import { Component, Input } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { IUserInfo } from 'src/app/core/interfaces';
import { RequestsService } from 'src/app/core/services/requests.service';
import { UtilsService } from '../../../services/utils.service';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent {


icon:string = "";
subTitle:string=""; 
subTitle0:string="";
subTitle1:string="";
categories:string="";
  
items:any[] = [];
processes:string[]=[];
references:string[]=[];
backgroundImg = "";
prices:any=[];
labels:any = {};

isPriceFrom:boolean = true; 
errorMessage="";
successFull=""
budgets: string[] = ["Low budget", "€1.000 - €2.000", "€2.000 - €5.000", "€5.000 - €10.000", "€10.000+"];
userInfo = new FormGroup({
  fullName: new FormControl("", Validators.required),
  email: new FormControl("", Validators.required),
  subject: new FormControl("", Validators.required),
  budget: new FormControl(""),
  message: new FormControl("", Validators.required),
}
)
  constructor(private translateService:TranslateService, private requestService: RequestsService,public utilsService:  UtilsService){}
  ngOnInit(){
    this.getLabels();
  }
  
  submitForm() {
    this.successFull = "";
    this.errorMessage = "";

    if (this.userInfo.valid) {
      const test = this.userInfo.value
      this.requestService.addTalk(test as IUserInfo)
        .then((result: any) => {
          this.userInfo.reset();
          this.successFull = this.translateService.instant("home.lets-talk.success-message-sent")
          setTimeout(() => {
            this.successFull = "";
          }, 5000);
        })
        .catch((err: any) => {
          this.errorMessage = `${this.translateService.instant("home.lets-talk.error-message-sent")}: ${err.error.error.message}`
          setTimeout(() => {
            this.errorMessage = "";
          }, 5000);
        })
    }
  }

  getLabels() {
    this.requestService.getContactLabels()
      .subscribe((res: any) => {
        if (Array.isArray(res.data)) {
          this.labels = res.data.reduce((acc: any, response: any) => {
            acc[response.attributes.key] = response.attributes.value;
            return acc;
          }, {});
        } else if (res.data.attributes) {
          this.labels = Object.keys(res.data.attributes).reduce((acc: any, key: string) => {
            acc[key] = res.data.attributes[key];
            return acc;
          }, {});
        }
      });
  }

  getSeo() {
    this.requestService.getSeo().then((seo: any) => { 
      this.utilsService.updateTags(seo.data.find((page: any) => page?.attributes?.page == 'ContactUs'))
    })
  }
}

