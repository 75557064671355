<app-default-page
icon="phone"
[title]="('mobile-applications.title'|translate)"
[subTitle]="('mobile-applications.card-sub-title'|translate)" 
[subTitle0]="mobileAppTitle"
[subTitle1]="mobileAppText"
[categories]="('mobile-applications.mobile-categories'|translate)"
[items]="items"
[backgroundImg]="utilsService.backgrounds.mobile_apps"
[processes]="processes"
[prices]="prices"
[references]="references"

></app-default-page>