<div class="text-center">
  <div class="position-relative">
    <div class="dark-background bg-cover" [ngStyle]="{'background-image': 'url('+(utilsService.backgrounds.about_us || '')+')'}">
      <div class="fron-text header-section">
        <label class="page-title">{{'about-us.title'|translate}}</label>
        <br />
        <div class="d-flex gap-3">
          <b class="text-secondary">{{'home.title' |translate | uppercase }}</b>
          <b class="text-light">/</b>
          <b class="text-light">{{ 'about-us.title' | translate | uppercase }}</b>
        </div>
      </div>
    </div>
  </div>

  <div class="body-section centered">

    <h1 class="primary-title my-5 text-center">{{'about-us.title' |translate}}</h1>
    <h1 class="disabled-text">{{'about-us.sub-title' |translate}}</h1>
    <br />
    <h6 class="disabled-2-text content-1 text-start">{{'about-us.section1-content'|translate}}</h6>

  </div>

  <div class="row mx-auto centered">
    <div class="col-sm-12 col-md-6">
      <h1 class="disabled-text mb-3">{{'about-us.sub-title2' |translate}}</h1>
      <h6 class="disabled-2-text content-1 text-start">{{'about-us.section2-content' |translate}}</h6>
    </div>
    <div class="col-sm-12 col-md-6">
      <h1 class="disabled-text mb-3">{{'about-us.sub-title3' |translate}}</h1>
      <h6 class="disabled-2-text content-1 text-start">{{'about-us.section3-content' |translate}}</h6>
    </div>
  </div>

</div>