<div class="our-projects" *ngIf="titleDescription">
  <div class="default-padding">
    <h2 class="secondary-title">{{ titleDescription?.title }}</h2>
    <label class="white-text my-4">
      {{ titleDescription?.description1 }} <br />
      {{ titleDescription?.description2 }}
    </label>
    <!-- Categories -->
    <div class="categories mb-4">
      <div *ngFor="let category of categories" class="pointer"
        [ngClass]="selectedCategory === category.id ? 'is-selected' : ''" (click)="filterBy(category.id)">
        {{ category.title | uppercase }}
      </div>
    </div>

    <div class="row mx-auto gap-2 projects-container">
      <div *ngFor="let project of filteredProjects" class="col-sm-12 col-md-4 pointer test h-300-cover"
        [ngClass]="{'has-text': project.text}" (mouseover)="onMouseOver(project)" (mouseleave)="onMouseLeave(project)">
        <div class="image-section" (click)="openCarousel(project)">
          <div class="background-image">
            <img class="project-image lazyload" [src]="project.image" [alt]="project.alt">
          </div>
        </div>
        <div *ngIf="project.text" class="text-section" (click)="showMoreText(project)">
          <div class="text-content">{{ project.text }}</div>
        </div>
      </div>
    </div>
  </div>

  <!-- Slider -->
  <div id="carouselExample" class="carousel d-flex flex-column justify-content-center" *ngIf="projectsCarousel"
    (click)="projectsCarousel = false">
    <div class="close-icon-container">
      <h1 class="close-icon"><i class="bi bi-x-lg" (click)="projectsCarousel = false"></i></h1>
    </div>
    <div class="carousel-inner">
      <div *ngFor="let project of ourProjects; let i = index" class="carousel-item"
        [ngClass]="i === selectedIndex ? 'active' : ''">
        <img [src]="project.image" [alt]="project.alt" class="d-block w-100 high-quality-image">
      </div>
    </div>
    <button class="carousel-control-prev btn-lg" (click)="$event.stopPropagation()" type="button"
      data-bs-target="#carouselExample" data-bs-slide="prev">
      <span class="carousel-control-prev-icon w-h-200" aria-hidden="true"></span>
      <span class="visually-hidden">Previous</span>
    </button>
    <button class="carousel-control-next btn-lg" (click)="$event.stopPropagation()" type="button"
      data-bs-target="#carouselExample" data-bs-slide="next">
      <span class="carousel-control-next-icon w-h-200" aria-hidden="true"></span>
      <span class="visually-hidden">Next</span>
    </button>
  </div>
</div>