<div class="gray-light-background px-5 price-overview-container">
  <div class="default-padding">
    <h3 class="primary-title">{{'mobile-applications.price-title' | translate}}</h3>

    <label class="mb-5 py-5">{{'mobile-applications.price-sub-title' | translate}}</label>

    <div class="row mx-auto text-center price-container">

      <div class="col-sm-12 col-md-4 price" *ngFor="let price of prices"
        [ngClass]="price.isPopular ? 'shadow' : 'mt-5'">

        <div class="header d-flex flex-column justify-content-center">
          <label *ngIf="price.isPopular" class="popular">{{'home.price-overview.popular' | translate | uppercase}}</label>
          <h3 class="price-title">{{price.title}}</h3>
          <span class="price-sub-title">{{price.subTitle}}</span>
        </div>

        <div class="from-price d-flex align-items-center justify-content-center flex-column">
          <div>
            <sup *ngIf="isPriceFrom">{{'home.price-overview.from' | translate}}</sup>
            <sup> €</sup>
            <label>{{price.price}}</label>
          </div>
          <label class="payment">{{price.payment}}</label>
        </div>

        <div class="px-5">
          <div *ngFor="let option of price.options;let i=index" class="py-3"
            [ngClass]=" i === price.options.length - 1 ? '' : 'border-bottom' ">
            <i class="bi bi-check-circle"></i> {{option}}
          </div>
        </div>

        <button class="btn-outline-primary my-4"
          [ngClass]="price.isPopular ? 'btn-outline-primary-selected' : ''">{{'home.price-overview.button'
          |translate}}</button>

          <br/>

          <label class="footer">{{price.footer}}</label>

      </div>

    </div>
  </div>
</div>