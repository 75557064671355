import { Component, Input } from '@angular/core';
import { RequestsService } from 'src/app/core/services/requests.service';
import { environment } from 'src/app/environments';

@Component({
  selector: 'app-our-clients',
  templateUrl: './our-clients.component.html',
  styleUrls: ['./our-clients.component.scss']
})
export class OurClientsComponent {
  @Input() ourClient: any;
  cmsUrl = environment?.baseUrl;
  ourClients:any = [];

constructor(private requestService:RequestsService){}

ngOnInit(){
  // this.requestService.getOurClients()
  // .then((clients:any)=> {
  //   this.ourClients = clients.data.map((client:any)=>{
  //     return {
  //       image: `${environment?.baseUrl}${client?.attributes?.image?.data?.attributes?.url}`,
  //       alt: `${environment?.baseUrl}${client?.attributes?.image?.data?.attributes?.alternativeText}`,
  //     }
  //   })
  // })
}
}
