import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Title, Meta } from '@angular/platform-browser';
import {environment} from '../../environments'

interface MetaSocialImage {
  data?: {
    attributes?: {
      url?: string;
      width?: number;
      height?: number;
    }
  }
}

interface MetaSocialNetwork {
  socialNetwork: string;
  title: string;
  description: string;
  image?: MetaSocialImage;
}

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  backgrounds: any = {
    about_us: "",
    brochures_flyers_catalogs: "",
    business_cards: "",
    careers: "",
    createdAt: "",
    desktop_applications: "",
    interactive_pdf_forms: "",
    letterhead: "",
    logo_design: "",
    maintenance: "",
    mobile_apps: "",
    more_graphic_design_services: "",
    online_marketer: "",
    publishedAt: "",
    updatedAt: "",
    web_applications: "",
    web_design: "",
    contact:""
  };

  seo : any;

  constructor(private router: Router, private titleService: Title, private meta: Meta) { }

  goTo(path: string) {
    this.router.navigate([path])
  }

  setTitle(newTitle: string, newDescription?: any) {
    this.titleService.setTitle(newTitle);
  }

  public updateTags(seoData: any): void {
    if (!seoData || seoData.length === 0) {
        console.warn('No SEO data provided');
        return;
    }

    this.seo = seoData.attributes.seo;
    console.log(seoData);
    if (this.seo) {
        this.updateMetaTags(this.seo);
        this.updateSocialTags(this.seo.metaSocial);
        this.updateStructuredData(this.seo.structuredData);
        if (this.seo?.Google && seoData.attributes.page === 'HomeComponent') {
            this.setupGoogleAnalytics(this.seo?.Google?.googleAnalyticsId);
        }
    } else {
        console.warn('No valid SEO configuration found in seo');
    }
}


private updateMetaTags(tags: any): void {
  const robotsContent = tags?.metaRobots?.replace(/.*content="([^"]+).*/, '$1') || 'index, follow';

  this.titleService.setTitle(tags?.metaTitle || 'iSolve');
  this.meta.updateTag({ name: 'description', content: tags?.metaDescription || 'Default description' });
  this.meta.updateTag({ name: 'keywords', content: tags?.keywords || 'Default keywords' });
  this.meta.updateTag({ name: 'robots', content: robotsContent });
  this.meta.updateTag({ name: 'viewport', content: tags?.metaViewport || 'width=device-width, initial-scale=1' });
  
  if (tags?.canonicalURL) {
      this.meta.updateTag({ name: 'canonical', content: tags?.canonicalURL });
  }
  if(tags?.Google?.verificationToken){
    this.meta.updateTag({name: 'google-site-verification', content: tags?.Google?.verificationToken})
  }
}

private updateSocialTags(metaSocial: MetaSocialNetwork[]): void {
  if (!Array.isArray(metaSocial)) return;

  const defaultTags = [
    { property: 'og:type', content: 'website' },
    { property: 'og:site_name', content: 'iSolve' },
    { property: 'og:url', content: this.getCanonicalUrl() },
    { property: 'og:title', content: this.seo.metaTitle },
    { property: 'og:description', content: this.seo.metaDescription }
  ];

  const defaultImageUrl = this.getAbsoluteUrl(this.seo.metaImage?.data?.attributes?.url);
  if (defaultImageUrl) {
    defaultTags.push({ property: 'og:image', content: defaultImageUrl });
    
    const mainImage = this.seo.metaImage?.data?.attributes;
    if (mainImage?.width && mainImage?.height) {
      defaultTags.push(
        { property: 'og:image:width', content: mainImage.width.toString() },
        { property: 'og:image:height', content: mainImage.height.toString() }
      );
    }
  }

  defaultTags.forEach(tag => this.meta.updateTag(tag));

  metaSocial.forEach(social => {
    const network = social.socialNetwork.toLowerCase();
    const imageUrl = this.getAbsoluteUrl(social?.image?.data?.attributes?.url || '');

    if (network === 'facebook') {
      const fbTags = [
        { property: 'og:title', content: social.title },
        { property: 'og:description', content: social.description }
      ];
      
      if (imageUrl) {
        fbTags.push({ property: 'og:image', content: imageUrl });
      }

      fbTags.forEach(tag => this.meta.updateTag(tag));
    }
    else if (network === 'twitter') {
      const twitterTags = [
        { name: 'twitter:card', content: 'summary_large_image' },
        { name: 'twitter:title', content: social.title },
        { name: 'twitter:description', content: social.description }
      ];

      if (imageUrl) {
        twitterTags.push({ name: 'twitter:image', content: imageUrl });
        if (social.title) {
          twitterTags.push({ name: 'twitter:image:alt', content: social.title });
        }
      }

      twitterTags.forEach(tag => this.meta.updateTag(tag));
    }
  });

  if (!metaSocial.some(social => social.socialNetwork.toLowerCase() === 'twitter')) {
    const twitterFallbackTags = [
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:title', content: this.seo.metaTitle },
      { name: 'twitter:description', content: this.seo.metaDescription }
    ];

    if (defaultImageUrl) {
      twitterFallbackTags.push({ name: 'twitter:image', content: defaultImageUrl });
    }

    twitterFallbackTags.forEach(tag => this.meta.updateTag(tag));
  }
}

private getAbsoluteUrl(imagePath: string): string {
  return imagePath?.startsWith('http') 
    ? imagePath 
    : `${environment.baseUrl}${imagePath}`;
}

private getCanonicalUrl(): string {
  return this.seo?.canonicalURL || 'https://www.i-solve.info';
}

  private updateStructuredData(data: any): void {
    if (!data) return;
    const scriptTag = document.createElement('script');
    scriptTag.type = 'application/ld+json';
    scriptTag.text = JSON.stringify(data);
    const existingScript = document.querySelector('script[type="application/ld+json"]');
    if (existingScript) {
      document.head.removeChild(existingScript);
    }
    document.head.appendChild(scriptTag);
  }

  private setupGoogleAnalytics(gaId: string): void {
    if (gaId) {
      const gaScript = document.createElement('script');
      gaScript.async = true;
      gaScript.src = `https://www.googletagmanager.com/gtag/js?id=${gaId}`;
      document.head.appendChild(gaScript);

      const configScript = document.createElement('script');
      configScript.text = `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', '${gaId}', {
          'anonymize_ip': true,
          'cookie_flags': 'SameSite=None;Secure'
        });
      `;
      document.head.appendChild(configScript);
    }
  }
}
