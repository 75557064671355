<app-default-page
icon="lightbulb"
[title]="('more-graphic-design-services.title'|translate)"
[subTitle]="('more-graphic-design-services.card-sub-title'|translate)" 
[subTitle0]="('more-graphic-design-services.subTitle0'|translate)"
[subTitle1]="('more-graphic-design-services.subTitle1'|translate)"
[categories]="('more-graphic-design-services.webapp-categories'|translate)"
[items]="items"
[backgroundImg]="utilsService.backgrounds.more_graphic_design_services"
[processes]="processes"
[references]="references"
[prices]="prices"
></app-default-page>