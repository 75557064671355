import { Component } from '@angular/core';
import { UtilsService } from '../../services/utils.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
  constructor(public utilsService: UtilsService) { }
  goTo(path: string) {
    this.utilsService.goTo(path)
  }
  get year() {
    return new Date().getFullYear();
  }
}
