<app-default-page
icon="bag"
[title]="('online-marketing.title'|translate)"
[subTitle]="('online-marketing.card-sub-title'|translate)" 
[subTitle0]="('online-marketing.subTitle0'|translate)"
[subTitle1]="('online-marketing.subTitle1'|translate)"
[categories]="('online-marketing.webapp-categories'|translate)"
[items]="items"
[backgroundImg]="utilsService.backgrounds.online_marketer"
[processes]="processes"
[prices]="prices"

></app-default-page>