import { Component, Input } from '@angular/core';
import { RequestsService } from 'src/app/core/services/requests.service';
import { UtilsService } from 'src/app/core/services/utils.service';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent {
@Input() faqs: any;
//faqs:any=[];

constructor(private requestService:RequestsService, public utilsService: UtilsService){}

ngOnInit(){
//this.getFaqs();
}

getFaqs(){
this.requestService.getFaqs()
  .then((faqs:any)=>{
    this.faqs = faqs.data.map((faq:any)=>{
      return {
        id:faq.id,
        question: faq.attributes.question,
        answer: faq.attributes.answer,
      }
    })
  })
}
}
