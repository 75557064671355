<div *ngIf="productsStats" class=" bg-secondary products-stats-container">
  <div class="default-padding-1 row mx-auto my-5 overflow-x-hidden">

    <div class="col-sm-12 col-md-12 col-lg-4 offset-0 offset-lg-1 p-0">
      <h2 class="primary-title mb-5">{{productsStats?.title}}</h2>
      <label class="white-text2 mb-3">{{productsStats?.description}}</label>
    </div>
    <div class="col-md-12 col-lg-6">
      <div class="row">
        <div class="col-sm-12 col-md-4" *ngFor="let stats of productsStats?.statistics">
          <div class="primary-text">{{stats?.amount}}</div>
          <div class="white-text">{{stats?.title}}</div>
        </div>
      </div>
    </div>
  </div>
</div>