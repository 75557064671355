import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-phase',
  templateUrl: './phase.component.html',
  styleUrls: ['./phase.component.scss']
})
export class PhaseComponent implements OnInit {
  @Input() textInside: any = "";
  @Input() textButton:string = "";
  text: string = '';
  fontSize: string = '16px';

  ngOnInit(): void {
      this.text = this.textInside.text;
      this.fontSize = this.textInside?.textSize;
  }
}
