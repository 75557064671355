import { Component } from '@angular/core';
import { RequestsService } from '../../../services/requests.service';
import { environment } from 'src/app/environments';
import { UtilsService } from 'src/app/core/services/utils.service';

@Component({
  selector: 'app-maintenance',
  templateUrl: './maintenance.component.html',
  styleUrls: ['./maintenance.component.scss']
})
export class MaintenanceComponent {

  items: any = [];
  processes: any = [];
  prices: any = [];
  references: any = [];
  cmsUrl = environment.baseUrl;

  constructor(private requestsService: RequestsService, public utilsService:UtilsService) { }
  ngOnInit() {
    this.getMaintenance();
    this.getMaintenanceProcesses();
    this.getMaintenancePrices();
    this.utilsService.setTitle("Maintenance - iSolve")
    this.getSeo()
  }

  getMaintenance() {
    this.requestsService.getMaintenance()
      .then((apps: any) => {
        this.items = apps.data.map((app: any) => {
          return {
            id: app.id,
            title: app.attributes.title,
            text: app.attributes.text,
            iconUrl: `${this.cmsUrl}${app.attributes.icon.data[0].attributes.url}`,
            iconHoverUrl: `${this.cmsUrl}${app.attributes.iconHover.data[0].attributes.url}`
          }
        })
      })
  }

  
  getIconUrl(items: any): string {
    return `${this.cmsUrl}${items.attributes.icon.data.attributes.url}`;
  }
  
  getHoverIconUrl(items: any): string {
    return `${this.cmsUrl}${items.iconHover.data.attributes.url}`;
  }


  getMaintenanceProcesses() {
    this.requestsService.getMaintenanceProcesses()
      .then((apps: any) => {
        this.processes = apps.data.map((app: any) => app.attributes).sort((a: any, b: any) => a.id - b.id)
      })
  }

  getMaintenancePrices() {
    this.requestsService.getMaintenancePrices()
      .then((apps: any) => {
        this.prices = apps.data.map((price: any) => {
          return {
            id: price.id,
            title: price.attributes.title,
            subTitle: price.attributes.sub_title,
            price: price.attributes.price,
            isPopular: price.attributes.isPopular,
            options: price.attributes.options.split(";"),
          }
        }).sort((a: any, b: any) => a.id - b.id)
      })
  }

  getSeo() {
    this.requestsService.getSeo().then((seo: any) => { 
      this.utilsService.updateTags(seo.data.find((page: any) => page?.attributes?.page == 'Maintenance'))
    })
  }
}
