<app-default-page
icon="file-earmark-text"
[title]="('letterhead.title'|translate)"
[subTitle]="('letterhead.card-sub-title'|translate)" 
[subTitle0]="('letterhead.subTitle0'|translate)"
[subTitle1]="('letterhead.subTitle1'|translate)"
[categories]="('letterhead.webapp-categories'|translate)"
[items]="items"
[backgroundImg]="utilsService.backgrounds.letterhead"
[processes]="processes"
[prices]="prices"
[isPriceFrom]="false"

></app-default-page>