<div class="text-center">
  <div class="position-relative">
    <div class="dark-background bg-cover" [ngStyle]="{'background-image': 'url('+utilsService.backgrounds.careers+')'}">
      <div class="fron-text header-section">
        <label class="page-title">{{ careersTitle }}</label>
        <br />
        <div class="d-flex gap-3">
          <b class="text-secondary">{{ homeTitle | uppercase }}</b>
          <b class="text-light">/</b>
          <b class="text-light">{{  careersTitle | uppercase }}</b>
        </div>
      </div>
    </div>
  </div>

  <div class="body-section">

    <h1 class="primary-title my-5 text-center">{{ careersSubtitle0 }}</h1>
    <label class="disabled-text">{{ careersSubtitle1 }}</label>

    <!-- Positions -->
    <div class="row mx-auto mt-5">
      <div *ngFor="let career of careers" class="col-sm-12 col-md-6 col-lg-4 col-xl-3 career-card pointer my-3" (click)="showCareerPositionModal(career)">
        <div class="card-container">
          <div class="p-3 h-150">
            <h5 class="primary-sub-title">{{career.title}}</h5>
            <label class="primary-text font-roboto w-100 text-start my-4">{{'careers.read-more'|translate |
              uppercase}}</label>
          </div>
          <div class="date-published text-disabled text-start d-flex flex-column">
            <hr class="mb-2" />
            <label class="text-disabled-3 date-text">{{career.createdAt | date: 'MMMM dd, YYYY'}}</label>
          </div>
        </div>
      </div>
    </div>

  </div>

</div>


<!-- showCareerPositionModal -->
<div class="modal" id="showCareerPositionModal"
[ngStyle]="{'display': showCareerPosition ? 'flex' : 'none'}">
  <div class="modal-dialog modal-fullscreen">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title fs-5" id="showCareerPositionModalLabel">{{career?.title}}</h1>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="closeModal()"></button>
      </div>
      <div class="modal-body">
        <app-career-position [career]="career"></app-career-position>
      </div>
    </div>
  </div>
</div>