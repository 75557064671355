import { Component, ElementRef, ViewChild } from '@angular/core';
import { environment } from 'src/app/environments';
import { LANGUAGES } from '../../constants';
import { ActivatedRoute, Router } from '@angular/router';
import { UtilsService } from '../../services/utils.service';
import { RequestsService } from '../../services/requests.service';

const IMG_SOFTWARE_MARKETING = ["/uploads/i_Solve_Software_marketing_63dd95f270.webp","/uploads/i_Solve_Software_design_94bc2fc965.webp"]

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent {

  // imgSoftwareMarketing = IMG_SOFTWARE_MARKETING.map(img=> environment.baseUrl+img);
  slideIndex:number = 0;
  @ViewChild('section1', { read: ElementRef }) section1!: ElementRef;
  content: any;
  cmsUrl = environment.baseUrl;

constructor(private router: Router,private route: ActivatedRoute, private el: ElementRef, private requestsService: RequestsService, private utilsService: UtilsService){}
ngOnInit() {
  this.requestsService.getHomePage()
    .subscribe(
      (response: { data: any }) => {
        this.content = response.data.attributes;
        setTimeout(() => this.showSlides(), 0); 
      },
      (error) => {
        console.error('Error fetching home page data:', error);
      }
    );

  this.getSeo();
}

ngAfterViewInit() {
  const paramSection = this.route.snapshot.paramMap.get('section');
  const element = this.el.nativeElement.querySelector(`#${paramSection}`);
  if (element) {
    element.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }
}

showSlides() {
  let i;
  const slides = document.getElementsByClassName("mySlides") as HTMLCollectionOf<HTMLElement>;
  const dots = document.getElementsByClassName("dot") as HTMLCollectionOf<HTMLElement>;

  if (!slides.length || !dots.length) return; // Ensure there are slides and dots
  
  for (i = 0; i < slides.length; i++) {
    slides[i].style.display = "none";  
  }
  
  this.slideIndex++;
  if (this.slideIndex > slides.length) {
    this.slideIndex = 1;
  }    
  
  for (i = 0; i < dots.length; i++) {
    dots[i].className = dots[i].className.replace(" active", "");
  }

  slides[this.slideIndex - 1].style.display = "block";  
  dots[this.slideIndex - 1].className += " active";
  
  setTimeout(() => this.showSlides(), 5000); // Change image every 5 seconds
}

goToTop() {
  window.scroll({ 
          top: 0, 
          left: 0, 
          behavior: 'smooth' 
   });
}

get language(){
  return localStorage.getItem("language") || LANGUAGES[0].languageShort;
}

goToOurServices(){
    this.section1.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
}

getSeo() {
  this.requestsService.getSeo().then((seo: any) => { 
    this.utilsService.updateTags(seo.data.find((page: any) => page?.attributes?.page == 'HomeComponent'))
  })
}


}
