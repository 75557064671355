import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-products-stats',
  templateUrl: './products-stats.component.html',
  styleUrls: ['./products-stats.component.scss']
})
export class ProductsStatsComponent {
  @Input() productsStats: any;

}
