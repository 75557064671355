import { Component, Input } from '@angular/core';
import { RequestsService } from 'src/app/core/services/requests.service';
import { UtilsService } from 'src/app/core/services/utils.service';
import { environment } from 'src/app/environments';

@Component({
  selector: 'app-technologies',
  templateUrl: './technologies.component.html',
  styleUrls: ['./technologies.component.scss']
})
export class TechnologiesComponent {
  @Input() technology: any;
  cmsUrl = environment?.baseUrl;

  techsCategories: { id: number, name: string,children:{id:number,image:string, name:string, alt: string}[] }[] = [];
  techs = [];

  constructor(private requestService: RequestsService, public utilsService:UtilsService) { }
  ngOnInit() {
    //this.getTechs();
    // this.getTechsCategories()
  }

  getTechsCategories() {
    this.requestService.getTechnologyCategories()
      .then((techsCategories: any) => {
        this.techsCategories = techsCategories.data.map((tech: any) => { 
          return { 
            id: tech.id, 
            name: tech.attributes.Name,
            children: this.techs.filter((t:any)=>t.parent === tech.id)
           } })
      })
  }

  getTechs(){
    this.requestService.getTechnology()
    .then((techsCategories: any) => {
      this.techs = techsCategories?.data.map((tech: any) => { 
        return { 
          id: tech?.id, 
          name: tech.attributes.name,
          image: `${environment?.baseUrl}${tech?.attributes?.image?.data?.attributes?.url}`,
          alt: `${environment.baseUrl}${tech?.attributes?.image?.data?.attributes?.alternativeText}`,
          parent: tech?.attributes?.category?.data?.id
    }})
    this.getTechsCategories();
      
    })
  }

  getBorders(index: number) {
    if(window.innerWidth >= 768){
    if (index === 0) { return 'border-bottom border-end' }//if it's the first one, right and bottom border
    else if (index === this.techsCategories.length - 1) { return '' } //if it's the last one, no borders
    else if (index % 2 === 0 || index === this.techsCategories.length - 2) { return 'border-end' }
    else if (index % 2 === 1) { return 'border-bottom' }
    else { return '' }
    }
    else{     
    if (index === this.techsCategories.length - 1) { return '' } //if it's the last one, no borders
    else { return 'border-bottom' }
    }
  }

}
