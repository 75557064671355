import { Component, Input } from '@angular/core';


@Component({
  selector: 'app-default-page',
  templateUrl: './default-page.component.html',
  styleUrls: ['./default-page.component.scss']
})
export class DefaultPageComponent {

  @Input() iconMain:string = "";
  @Input() title:string="";
  @Input() subTitle:string=""; 
  @Input() subTitle0:string="";
  @Input() subTitle1:string="";
  @Input() categories:string="";
  @Input() icon: string = "";
  @Input() iconHover:string = "";
  @Input() items:any[] = [];
  @Input() processes:string[]=[];
  @Input() references:any=[];
  @Input() backgroundImg = "";
  @Input() prices:any=[];

  @Input() isPriceFrom:boolean = true; 
  
  constructor(){}
  ngOnInit(){ 
    this.goToTop();
  }
  goToTop() {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'auto',
    });
  }

}
