<div class="container">

  <!-- big circle -->
  <div class="circle">
  <div class="inner-circle dynamic-font" [ngStyle]="{'--font-size': fontSize}">
    {{text}}
  </div>
  </div>

  <!-- vettical lign and circle -->
  <div class="vertical-line-container">
    <div class="vertical-line"></div>
    <div class="small-circle"></div>
  </div>

  <div class="box-shadow-bottom"></div>

  <div class="btn-container">
  <button class="btn-primary">{{textButton}}</button>
  </div>

</div>