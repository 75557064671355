import { Component, Input, OnInit } from '@angular/core';
import { LANGUAGES } from 'src/app/core/constants';
import { RequestsService } from 'src/app/core/services/requests.service';
import { UtilsService } from 'src/app/core/services/utils.service';
import { environment } from 'src/app/environments';

@Component({
  selector: 'app-first-section',
  templateUrl: './first-section.component.html',
  styleUrls: ['./first-section.component.scss']
})
export class FirstSectionComponent implements OnInit {

  @Input() language: string = LANGUAGES[0].languageShort;
  @Input() isolveComponent: any;
  cmsUrl = environment?.baseUrl;
  moveY: string = "";

  constructor(private requestService: RequestsService, private utilsService: UtilsService) {
    window.addEventListener('scroll', () => {
      const scrolled = window.scrollY;
      this.moveY = (scrolled * 0.2) + 'px';
    });
  }

  ngOnInit() {
    if (this.isolveComponent) {
      this.isolveComponent.cards.sort((a: any, b: any) => a.id - b.id);
    }
    this.getIsolveSection();
  }

  getIsolveSection() {
    (response: any) => {
      this.isolveComponent = response.data.map((response: any) => {
      this.isolveComponent = {
        id: response.id,
        title: response.title,
        description: response.description,
        cards: response.cards.map((card: any) => ({
          id: card.id,
          title: card.title,
          text: card.text,
          icon: card.icon,
          iconHover: card.iconHover,
          redirectTo: card.redirectTo,
          hovering: false
        }))
      };
      this.isolveComponent.cards.sort((a: any, b: any) => a.id - b.id);
    });
  }
}

  getIconUrl(card: any): string {
    return `${this.cmsUrl}${card.icon.data[0].attributes.url}`;
  }

  getHoverIconUrl(card: any): string {
    return `${this.cmsUrl}${card.iconHover.data[0].attributes.url}`;
  }

  onMouseEnter(card: any) {
    card.hovering = true;
  }

  onMouseLeave(card: any) {
    card.hovering = false;
  }

  goTo(path: string) {
    this.utilsService.goTo(path);
  }
}
