export const LANGUAGES = [
  {
    language:"english",
    languageShort:"en"
  },
  {
    language:"shqip",
    languageShort:"sq"
  },
  {
    language:"македонски",
    languageShort:"mk"
  }
]

export const CONOME_MK="https://conome.mk"