import { Component } from '@angular/core';
import { RequestsService } from 'src/app/core/services/requests.service';
import { UtilsService } from 'src/app/core/services/utils.service';
import { environment } from 'src/app/environments';

@Component({
  selector: 'app-online-marketing',
  templateUrl: './online-marketing.component.html',
  styleUrls: ['./online-marketing.component.scss']
})
export class OnlineMarketingComponent {

  items: any = [];
  processes: any = [];
  prices: any = [];
  references: any = [];
  cmsUrl = environment.baseUrl;

  constructor(private requestsService: RequestsService, public utilsService:UtilsService) { }
  ngOnInit() {
    this.getOnlineMarketing();
    this.getOnlineMarketingProcesses();
    this.getOnlineMarketingPrices();
    this.utilsService.setTitle("Online Marketer - iSolve")
    this.getSeo()
  }

  getOnlineMarketing() {
    this.requestsService.getOnlineMarketing()
      .then((apps: any) => {
        this.items = apps.data.map((app: any) => {
          return {
            id: app.id,
            title: app.attributes.title,
            text: app.attributes.text,
            iconUrl: `${this.cmsUrl}${app.attributes.icon.data[0].attributes.url}`,
            iconHoverUrl: `${this.cmsUrl}${app.attributes.iconHover.data[0].attributes.url}`
          }
        })
      })
  }

  
  getIconUrl(items: any): string {
    return `${this.cmsUrl}${items.attributes.icon.data.attributes.url}`;
  }
  
  getHoverIconUrl(items: any): string {
    return `${this.cmsUrl}${items.iconHover.data.attributes.url}`;
  }

  getOnlineMarketingProcesses() {
    this.requestsService.getOnlineMarketingProcesses()
      .then((apps: any) => {
        this.processes = apps.data.map((app: any) => app.attributes).sort((a: any, b: any) => a.id - b.id)
      })
  }

  getOnlineMarketingPrices() {
    this.requestsService.getOnlineMarketingPrices()
      .then((apps: any) => {
        this.prices = apps.data.map((price: any) => {
          return {
            id: price.id,
            title: price.attributes.title,
            subTitle: price.attributes.subTitle,
            price: price.attributes.price,
            isPopular: price.attributes.isPopular,
            options: price.attributes.options.split(";"),
            footer: price.attributes.footer,
            payment: price.attributes.payment,
          }
        }).sort((a: any, b: any) => a.id - b.id)
    })
  }
  
  getSeo() {
    this.requestsService.getSeo().then((seo: any) => { 
      this.utilsService.updateTags(seo.data.find((page: any) => page?.attributes?.page == 'OnlineMarketer'))
    })
  }
}
