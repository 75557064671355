import { Component } from '@angular/core';
import { RequestsService } from 'src/app/core/services/requests.service';
import { UtilsService } from 'src/app/core/services/utils.service';

@Component({
  selector: 'app-mobile-price-overview',
  templateUrl: './price-overview.component.html',
  styleUrls: ['./price-overview.component.scss']
})
export class MobilePriceOverviewComponent {

  prices: any = [];

  constructor(private requestsService: RequestsService, public utilsService: UtilsService) { }
  ngOnInit() {
    this.getPrices();
  }

  getPrices() {
    this.requestsService.getMobilePrices()
      .then((prices: any) => {
        this.prices = prices.data.map((price: any) => {
          return {
            id: price.id,
            title: price.attributes.title,
            subTitle: price.attributes.sub_title,
            price: price.attributes.price,
            isPopular: price.attributes.isPopular,
            options: price.attributes.options.split(";"),
          }
        }).sort((a: any, b: any) => a.id - b.id)
      })
  }

}
