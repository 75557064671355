<div id="progress-container">
  <div id="progress-bar"></div>
</div>

<app-menu></app-menu>

<router-outlet></router-outlet>

<!-- let's talk -->
<app-lets-talk></app-lets-talk>
<app-footer></app-footer>

<!-- Modal -->
<div class="modal fade" id="letsTalkModal" tabindex="-1" aria-bledby="letsTalkModalb" aria-hidden="true">
  <div class="modal-dialog modal-fullscreen">
    <div class="modal-content">
      <div class="modal-header border-0">
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-b="Close"></button>
      </div>
      <div class="modal-body">

        <h1 class="modal-title fs-5 primary-text" id="letsTalkModalb">{{'home.lets-talk.title'|translate}}</h1>
        <b class="disabled-text">{{'home.project-in-mind.modal.sub-title'|translate}}</b>
        <br />

        <form [formGroup]="userInfo" (ngSubmit)="submitForm()" class="mt-3">
          <label for="fullName"
            class="primary-text mt-5 mb-3">{{labels.fullName | uppercase}}</label><label
            class="text-danger">*</label>
          <input id="fullName" type="text" class="form-control form-control-lg" formControlName="fullName" />

          <label for="email" class="primary-text  mt-5 mb-3">
            {{labels.email | uppercase}}</label><label class="text-danger">*</label>
          <input id="email" type="email" class="form-control form-control-lg" formControlName="email" />

          <label for="subject" class="primary-text  mt-5 mb-3">
            {{labels.subject | uppercase}}
          </label>
          <label class="text-danger">*</label>
          <input id="subject" type="text" class="form-control form-control-lg" formControlName="subject" />

          <label for="budget" class="primary-text  mt-5 mb-3">
            {{labels.budget|uppercase}}
          </label>
          <select class="form-control form-control-lg" formControlName="budget">
            <option>Select budget</option>
            <option *ngFor="let budget of budgets" [value]="budget">{{budget}}</option>
          </select>

          <label for="message" class="primary-text  mt-5 mb-3">
            {{labels.message | uppercase}}
          </label>
          <label class="text-danger">*</label>
          <textarea id="message" rows="5" class="form-control form-control-lg" formControlName="message"></textarea>

          <button class="btn-primary-outline mt-5 btn-lg" type="submit">{{labels.buttonText}}</button>
        </form>

        <label class="text-success">{{successFull}}</label>
        <label class="text-danger">{{errorMessage}}</label>

      </div>
    </div>
  </div>
</div>