import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from './environments';

@Injectable()
export class RequestsInterceptor implements HttpInterceptor {


  constructor() {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
   
  const token = environment.api_token || "";

              if (token.length) {
                request = request.clone({
                      setHeaders: {
                          Authorization: `Bearer ${token}`
                      }
                  });
              }         
            return next.handle(request);
  }
}
