<div class="text-center">
  <div class="position-relative">
    <div class="dark-background bg-cover" [ngStyle]="{'background-image': 'url('+backgroundImg+')'}">
      <div class="fron-text header-section">
        <label class="page-title">{{title}}</label>
        <br />
        <div class="d-flex gap-3">
          <b class="text-secondary">{{'home.title' |translate | uppercase }}</b>
          <b class="text-light">/</b>
          <b class="text-light">{{ title | uppercase }}</b>
        </div>
      </div>
    </div>
  </div>

  <div class="presentation-section">
    <h1><i class="white-text bi" [ngClass]="'bi-'+icon"></i></h1>
    <div class="content">
      <h2>{{title}}</h2>
      <h6>{{subTitle}}</h6>
      <div class="arrow-down">
        <i class="bi bi-arrow-down"></i> <!--Don't change this icon-->
      </div>
    </div>
  </div>

  <div class="body-section">

    <h1 class="primary-title my-5 text-center">{{subTitle0}}</h1>
    <label>{{subTitle1}}</label>


    <!-- Items -->
    <div class="row mx-auto gap-5 items-container" *ngIf="items.length">
      <h1 class="primary-title my-5">{{categories}}</h1>

      <div *ngFor="let item of items; let i = index" class="webapp col-sm-12 col-md-4 col-lg-3" 
        (mouseenter)="item.hover = true" (mouseleave)="item.hover = false">

        <h1>
          <img [src]="item.hover ? item.iconHoverUrl : item.iconUrl" [width]="62" [height]="62" alt="{{item.title}} icon" />
        </h1>
        <h2 class="my-3 text-center"><b>{{item.title}}</b></h2>
        <br />
        <h6 class="disabled-text text-center">{{item.text}}</h6>

      </div>

    </div>


  </div>

  
    <!-- Process -->
    <div class="my-5" *ngIf="processes.length">
      <h1 class="primary-title">{{'webdesign.process' | translate}}</h1>
      <div class="phases-container ">
        <div *ngFor="let process of processes; let i=index">
          <div>
          <app-phase [textInside]="process" [textButton]="('webdesign.phase' | translate) + ' ' + (i+1)"></app-phase>
        </div>
        </div>
      </div>
    </div>


  <div *ngIf="prices.length">
    <app-prices 
    [prices]="prices" 
    [priceTitle]="('webdesign.price-title' | translate)"
    [priceSubTitle]="('webdesign.price-sub-title' | translate)"
    [isPriceFrom]="isPriceFrom"    
    ></app-prices>
  </div>

  <!-- References -->
  <div class="my-5" *ngIf="references.length">
    <h1 class="primary-title">{{'webdesign.references' | translate}}</h1>
    <div class="row mx-auto">
      <div *ngFor="let reference of references; let i=index" class="col-6">
        <div class="my-3">
          <img class="width-fill" [width]="841" [height]="560" [src]="reference.image" [alt]="reference.alt"/>
        </div>
      </div>
    </div>
  </div>

</div>