<app-default-page
icon="rulers"
[title]="('webdesign.title'|translate)"
[subTitle]="('webdesign.card-sub-title'|translate)" 
[subTitle0]="('webdesign.sub-title0'|translate)"
[subTitle1]="('webdesign.sub-title1'|translate)"
[categories]="('webdesign.webapp-categories'|translate)"
[items]="items"
[backgroundImg]="utilsService.backgrounds.web_design"
[processes]="processes"
[prices]="prices"
[references]="references"

></app-default-page>