<div *ngIf="isolveComponent" class="software-development-container px-5"
  [ngStyle]="{'background-image':'url('+(cmsUrl + isolveComponent?.bgImage?.data?.attributes?.url)+')', 'background-position-Y': moveY }">
  <div class="default-padding">
    <h1 class="primary-title mt-5">{{isolveComponent?.title}}</h1>
    <i class="fa-solid fa-ranking-star"></i>
    <label class="my-5">{{isolveComponent?.description}}</label>

    <div class="mt-5 row d-flex flex-row flex-wrap justify-content-center">


      <div class="col-sm-12 col-md-4 software-card" *ngFor="let s of isolveComponent?.cards"
        (click)="goTo(s.redirectTo)" (mouseenter)="onMouseEnter(s)" (mouseleave)="onMouseLeave(s)">
        <div class="image-container">
          <img [src]="s.hovering ? getHoverIconUrl(s) : getIconUrl(s)" alt="Software Icon" [width]="74" [height]="74"
            class="primary-text bi">
        </div>
        <div class="text-container">
          <h3>{{s.title}}</h3>
          <p>{{s.text}}</p>
          <div class="arrow-right-container">
            <i class="bi bi-arrow-right"></i>
          </div>
        </div>



      </div>
    </div>

  </div>

</div>