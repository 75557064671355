import { Component } from '@angular/core';
import { RequestsService } from '../../../services/requests.service';
import { environment } from 'src/app/environments';
import { UtilsService } from 'src/app/core/services/utils.service';
import { Title } from '@angular/platform-browser';

interface WebApplications {
  id: number,
  title: string,
  text: string,
  icon:string,
  iconHover:string
}

@Component({
  selector: 'app-web-applications',
  templateUrl: './web-applications.component.html',
  styleUrls: ['./web-applications.component.scss']
})
export class WebApplicationsComponent {
  items: WebApplications[] = [];
  processes: string[] = []
  references: string[] = []
  content: any= {};
  page: string = '';
  webApplicationText = '';
  webApplicationTitle = '';
  cmsUrl = environment.baseUrl;

  constructor(private requestsService: RequestsService, public utilsService: UtilsService) { }
  ngOnInit() {
    this.getWebApplications();
    this.getProcesses();
    this.getReferences();
    this.utilsService.setTitle("Web Applications - iSolve")
    this.getSeo();
    this.getWebApplicationText();
  }

  getWebApplications() {
    this.requestsService.getWebApplications()
      .then((apps: any) => {
        this.items = apps.data.map((app: any) => {
          return {
            id: app.id,
            title: app.attributes.title,
            text: app.attributes.text,
            iconUrl: `${this.cmsUrl}${app.attributes.icon.data[0].attributes.url}`,
            iconHoverUrl: `${this.cmsUrl}${app.attributes.iconHover.data[0].attributes.url}`
          }
        })
      })
  }

  
  getIconUrl(items: any): string {
    return `${this.cmsUrl}${items.attributes.icon.data.attributes.url}`;
  }
  
  getHoverIconUrl(items: any): string {
    return `${this.cmsUrl}${items.iconHover.data.attributes.url}`;
  }
  
  onMouseEnter(items: any) {
    items.hovering = true;
  }
  
  onMouseLeave(items: any) {
    items.hovering = false;
  }

  getWebApplicationText() {
    this.requestsService.getWebAppText()
      .subscribe((response: any) => {
        this.webApplicationTitle = response.data.attributes.title;
        this.webApplicationText = response.data.attributes.text;
      });
  }

  getProcesses() {
    this.requestsService.getProcesses()
      .then((apps: any) => {
        this.processes = apps.data.sort((a: any, b: any) => a.id - b.id).map((app: any) => app.attributes);
      })
  }

  getReferences() {
    this.requestsService.getSelectedProjects()
      .then((apps: any) => {
        this.references = apps.data.filter((app: any) => app.attributes.category.data.id === 2).map((app: any) => {
          return {
            image: `${environment.baseUrl}${app.attributes.image.data[0].attributes.url}`,
            alt: `${environment.baseUrl}${app.attributes.image.data[0].attributes.alternativeText}`
          }
        });
      })
  }

  getSeo() {
    this.requestsService.getSeo().then((seo: any) => { 
      this.utilsService.updateTags(seo.data.find((page: any) => page?.attributes?.page == 'WebApplications'))
    })
  }
}
