import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { RequestsService } from 'src/app/core/services/requests.service';
import { UtilsService } from 'src/app/core/services/utils.service';

@Component({
  selector: 'app-careers',
  templateUrl: './careers.component.html',
  styleUrls: ['./careers.component.scss']
})
export class CareersComponent implements OnInit {

  subTitle0 = "subTitle0";
  subTitle1 = "subTitle1";
  careers: any = [];
  career: any = null;
  careersTitle: string = '';
  homeTitle: string = '';
  careersSubtitle0: string = '';
  careersSubtitle1: string = '';
  showCareerPosition = false;

  constructor(private requestsService: RequestsService, public utilsService: UtilsService, private route: ActivatedRoute) { }

  ngOnInit() {
    const id = this.route.snapshot.paramMap.get('id');

    if (id && !isNaN(Number(id))) { 
      this.requestsService.getCareerById(Number(id))
        .then((career: any) => {
          this.career = {
            ...career.data.attributes,
            apply_now_with: career.data.attributes.apply_now_with?.split(";") || [],
            benefits: career.data.attributes.benefits?.split(";") || [],
            nice_to_have: career.data.attributes.nice_to_have?.split(";") || [],
            requirements: career.data.attributes.requirements?.split(";") || [],
            responsibilities: career.data.attributes.responsibilities?.split(";") || [],
            right_fit: career.data.attributes.right_fit?.split(";") || [],
          }
          this.showCareerPosition = true;
        })
    }

    this.requestsService.getCareers()
      .then((careers: any) => {
        this.careers = careers.data.map((c: any) => {
          return {
            ...c.attributes,
            id: c.id,
            apply_now_with: c.attributes.apply_now_with?.split(";") || [],
            benefits: c.attributes.benefits?.split(";") || [],
            nice_to_have: c.attributes.nice_to_have?.split(";") || [],
            requirements: c.attributes.requirements?.split(";") || [],
            responsibilities: c.attributes.responsibilities?.split(";") || [],
            right_fit: c.attributes.right_fit?.split(";") || [],
          }
        })
      });

    this.requestsService.getCareerContent().then((res: any) => {
      const data = res.data?.attributes;
      this.careersTitle = data?.careersTitle;
      this.homeTitle = data?.homeTitle;
      this.careersSubtitle0 = data?.careersSubtitle0;
      this.careersSubtitle1 = data?.careersSubtitle1;
    })
  }

  showCareerPositionModal(career: any) {
    this.showCareerPosition = true;
    this.career = career;
    window.history.replaceState(null, '', `/careers/${career.id}`);
  }

  getSeo() {
    this.requestsService.getSeo().then((seo: any) => { 
      this.utilsService.updateTags(seo.data.find((page: any) => page?.attributes?.page == 'Careers'))
    })
  }

  closeModal() {
    this.showCareerPosition = false;
    window.history.replaceState(null, '', "/careers");
  }
}
