import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { RequestsService } from '../../services/requests.service';
import { environment } from 'src/app/environments';
import { LANGUAGES } from '../../constants';

@Component({
  selector: 'app-career-position',
  templateUrl: './career-position.component.html',
  styleUrls: ['./career-position.component.scss']
})
export class CareerPositionComponent implements OnInit {
  @Input() career: any = null;
  application: any = {};
  jobApplication = new FormGroup({
    name: new FormControl("", Validators.required),
    email: new FormControl("", Validators.required),
    phone: new FormControl(""),
    title: new FormControl(this.career?.attributes?.title || ""),
    fileName: new FormControl(""),
    filePath: new FormControl(""),
    link: new FormControl(""),
    days: new FormControl(""),
    message: new FormControl(""),
  });
  isDisabled = true;
  selectedFile: File | null = null;
  messageError: string = "";
  messageSuccess: string = "";
  currentPath = "";
  selectedLanguage!: string;
  careerId!: string;

  constructor(
    private requestsService: RequestsService,
    private http: HttpClient,
    private router: Router,
    private translate: TranslateService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    const fullPath = document.location.href;
    const encodedPath = encodeURIComponent(fullPath);
    this.currentPath = `https://www.facebook.com/sharer/sharer.php?u=${encodedPath}&amp;src=sdkpreparse`;

    this.selectedLanguage = this.translate.currentLang;

    this.translate.onLangChange.subscribe(async res => {
      if (this.selectedLanguage !== res.lang) {
        this.selectedLanguage = res.lang;
        await this.updateLanguage(res.lang);
      }
    });
  }

  async getCareerContent(id: string) {
    try {
      const populateString = `?populate=deep&locale=${this.selectedLanguage}`;
      const res = await this.requestsService.getLocalizedCareerContent(id, populateString).toPromise();
      await this.handleLocalizedContent(res);
    } catch (err) {
      console.error('Error fetching career content:', err);
    }
  }

  async handleLocalizedContent(res: any) {
    if (res && res.data) {
      const careerLocale = res.data.attributes.locale;
      if (careerLocale === this.selectedLanguage) {
        this.career = res.data;
      } else if (res.data.attributes.localizations?.data) {
        const localizedContent = res.data.attributes.localizations.data.find((x: any) => x.attributes.locale === this.selectedLanguage);
        if (localizedContent) {
          await this.getCareerContent(localizedContent.id);
          this.router.navigate(['/career', localizedContent.id]);
        } else {
          console.error('No localized content found for the selected language.');
          this.router.navigate(['/career']);
        }
      }
    }
  }

  async updateLanguage(newLanguage: string) {
    localStorage.setItem("language", newLanguage);
    this.translate.use(newLanguage);

    if (this.careerId) {
      await this.getCareerContent(this.careerId);
    }
  }

  uploadFile() {
    const formData: FormData = new FormData();
    if (this.selectedFile !== null) {
      formData.append('files', this.selectedFile, this.selectedFile.name);
    }
    this.http.post(`${environment.baseUrl}/api/upload`, formData).subscribe((response: any) => {
      this.jobApplication.controls.title.setValue(this.career.attributes.title || "");
      
      this.jobApplication.controls.filePath.setValue(`${environment.baseUrl}${response[0].url}`);
      
      this.jobApplication.controls.fileName.setValue(`${response[0].name}`);

      this.requestsService.sendJobApplication(this.jobApplication.value).then((jobApplication: any) => {
        if (jobApplication.result) {
          this.messageSuccess = "Successfully applied.";
        } else {
          this.messageError = jobApplication.Output;
        }
      });
    });
  }

  sendJobApplication() {
    this.messageError = "";
    this.messageSuccess = "";
    if (!this.jobApplication.valid) {
      this.messageError = "Please fill all the fields";
      return;
    }
    this.uploadFile();
  }

  onFileSelected(event: any) {
    this.selectedFile = event.target.files[0];
  }

  get languages() {
    return LANGUAGES.map(language => language.languageShort);
  }

  get languageSelected() {
    return localStorage.getItem("language") || LANGUAGES[0].languageShort;
  }
}
