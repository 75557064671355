import { Component, HostListener, AfterViewInit, OnInit } from '@angular/core';
import { environment } from 'src/app/environments';
import { RequestsService } from '../../services/requests.service';
import { UtilsService } from 'src/app/core/services/utils.service';
import { IMenuItem } from '../../interfaces';
import { LANGUAGES } from '../../constants';
import { Router } from '@angular/router';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit, AfterViewInit {

  allMenuItems: IMenuItem[] = [];
  menuItems: IMenuItem[] = [];
  logoImg: string = "";
  logoImgMobile: string = "";
  socials: { icon: string, url: string }[] = [];
  isMenuVisible = true;
  isMobileMenuVisible = false;
  isDropdownOpen: boolean[] = [];

  constructor(private requestService: RequestsService, public utilsService: UtilsService, private router: Router) { }

  ngOnInit() {
    this.logoImg = environment.baseUrl + "/uploads/i_Solve_logo_c406f5f6dd.png";
    this.logoImgMobile = environment.baseUrl + "/uploads/i_Solve_mark_white_2f5cfde1d5.png";
    this.getMenuItems();
    // Get socials
    this.requestService.getSocials().subscribe((socials: any) => {
      this.socials = socials.data.map((social: any) => { return { icon: social.attributes.icon, url: social.attributes.url } }).splice(0, 3);
    });
    this.toggleMenuVisibility();
    this.menuItems.forEach(() => this.isDropdownOpen.push(false));
  }

  ngAfterViewInit() {
    if (window.innerWidth > 0 && this.isMenuVisible) {
      let lastScrollTop = 0;
      const menu = document.getElementsByClassName("menu-container")[0] as HTMLElement;
      const menuMobile = document.getElementsByClassName("menu-container-mobile")[0] as HTMLElement;

      window.addEventListener('scroll', function () {
        const scrollTop = window.scrollY || document.documentElement.scrollTop;

        if (scrollTop > lastScrollTop) {
          // Scrolling down, hide the menu
          menu?.classList.remove('shown');
          menuMobile.style.position = "fixed";
          menuMobile.style.top = "0px";
          menuMobile.style.zIndex = "3";
        } else {
          menu?.classList.add('shown');
          // Scrolling up, show the menu until a specific point
          if (scrollTop < 600) {
            menu!.style.display = "flex";
            menu!.style.flexDirection = "row";
            menu!.style.position = "";
            menu!.style.backgroundColor = 'white';
            menu!.style.color = 'var(--disabled)';

            const items: any = document.getElementsByClassName("dropdown-toggle") || [];
            for (let item of items) {
              (item as HTMLElement).style.color = "var(--disabled)";
            }

            const menuItems: any = document.getElementsByClassName("menu-item") || [];
            for (let item of menuItems) {
              (item as HTMLElement).style.color = "var(--disabled)";
            }

            const tags: any = menu.getElementsByTagName("i") || [];
            for (let item of tags) {
              (item as HTMLElement).style.color = "var(--disabled)";
            }

            menuMobile.style.position = "relative";
            menuMobile.style.top = "0px";
            menuMobile.style.zIndex = "3";

          } else {
            menu!.style.display = "flex";
            menu!.style.flexDirection = "row";
            menu!.style.position = "fixed";
            menu!.style.top = '0';
            menu!.style.backgroundColor = 'var(--primary)';
            menu!.style.width = '100%';
            menu!.style.color = 'white';

            const items: any = document.getElementsByClassName("dropdown-toggle") || [];
            for (let item of items) {
              (item as HTMLElement).style.color = "white";
            }

            const menuItems: any = document.getElementsByClassName("menu-item") || [];
            for (let item of menuItems) {
              (item as HTMLElement).style.color = "white";
            }

            const tags: any = menu.getElementsByTagName("i") || [];
            for (let item of tags) {
              (item as HTMLElement).style.color = "white";
            }

          }
        }

        lastScrollTop = scrollTop;
      });
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event): void {
    this.toggleMenuVisibility();
  }

  private toggleMenuVisibility(): void {
    const menu = document.getElementsByClassName("menu-container")[0] as HTMLElement;

    if (!menu) return;
    if (window.innerWidth > 990 && this.isMenuVisible) {
      menu.style.visibility = "visible";
    } else {
      menu.style.visibility = "hidden";
    }
  }

  getMenuItems() {
    // Get menu items
    this.requestService.getMenuItems().subscribe((menuItems: any) => {
      this.allMenuItems = menuItems.data
        .sort((a: any, b: any) => a.id - b.id)
        .map((item: any) => {
          return {
            id: item.id,
            title: item.attributes.title,
            parent: item.attributes.parent.data === null ? 0 : item.attributes.parent.data.id,
            redirectTo: item.redirectTo,
            children: menuItems.data.filter((menuItem: any) => menuItem.attributes.parent.data?.id === item.id)
          };
        });

      this.menuItems = this.allMenuItems.filter((item: any) => item.parent === 0).sort((a, b) => a.id - b.id);
    });
  }

  get languages() {
    return LANGUAGES.map(language => language.languageShort);
  }

  private closeAllDropdownsExcept(index: number) {
    this.isDropdownOpen = this.isDropdownOpen.map((_, i) => i === index ? this.isDropdownOpen[i] : false);
  }

  toggleDropdown(index: number) {
    this.closeAllDropdownsExcept(index);
    this.isDropdownOpen[index] = !this.isDropdownOpen[index];
  }

  goTo(path: string) {
    this.isMobileMenuVisible = false;
    if (path.startsWith('http://') || path.startsWith('https://')) {
      window.location.href = path;
    } else {
      this.router.navigate([path]);
    }
  }

  get languageSelected() {
    return localStorage.getItem("language") || LANGUAGES[0].languageShort;
  }

  updateLanguage(newLanguage: string) {
    localStorage.setItem("language", newLanguage);
    location.reload();
  }

  toggleMenu() {
    this.isMobileMenuVisible = !this.isMobileMenuVisible;
    if (!this.isMobileMenuVisible) {
      this.isDropdownOpen = this.isDropdownOpen.map(() => false);
    }
  }
}
