<div *ngIf="conomeSection" class="row mx-auto mt-5 px-3">
  <div class="col-sm-12 col-md-6 text-center d-flex flex-column justify-content-center">
    <div class="conome-icon-container mx-auto">
      <img [src]="cmsUrl + conomeSection?.image1?.data?.attributes?.url" alt="conomeIcon" [height]="60" [width]="60" />
    </div>
    <label class="conome-text">{{conomeSection?.title}}</label>
    <div>
      <button class="btn btn-lg btn-outline-primary rounded btn-conome"
        (click)="goToConome(conomeSection?.button?.redirectLink)">
        {{conomeSection?.button?.btnText}}
      </button>
    </div>
  </div>

  <div class="col-sm-12 col-md-6 text-center">
    <img class="conome-pc" [src]="cmsUrl + conomeSection?.image2?.data?.attributes?.url" alt="conomePC" />
  </div>

  <div class="col-12 elementor-shape-bottom" data-negative="true">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 100" preserveAspectRatio="none">
      <path class="elementor-shape-fill" d="M790.5,93.1c-59.3-5.3-116.8-18-192.6-50c-29.6-12.7-76.9-31-100.5-35.9c-23.6-4.9-52.6-7.8-75.5-5.3
      c-10.2,1.1-22.6,1.4-50.1,7.4c-27.2,6.3-58.2,16.6-79.4,24.7c-41.3,15.9-94.9,21.9-134,22.6C72,58.2,0,25.8,0,25.8V100h1000V65.3
      c0,0-51.5,19.4-106.2,25.7C839.5,97,814.1,95.2,790.5,93.1z"></path>
    </svg>
  </div>
</div>