import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/app/environments';

@Component({
  selector: 'app-work-from-home-boring',
  templateUrl: './work-from-home-boring.component.html',
  styleUrls: ['./work-from-home-boring.component.scss']
})
export class WorkFromHomeBoringComponent {
  @Input() workFromHome: any;
  cmsUrl = environment?.baseUrl;
  //workFromHomeBoring = `${environment.baseUrl}/uploads/work_from_home_boring_3899c03003.jpg`;
  constructor(private router:Router) {}

  goToCoworking(path:string){
    this.router.navigate([path]);
  }
}
