import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LANGUAGES } from 'src/app/core/constants';
import { RequestsService } from 'src/app/core/services/requests.service';
import { UtilsService } from 'src/app/core/services/utils.service';
import { environment } from 'src/app/environments';

@Component({
  selector: 'app-software-development',
  templateUrl: './software-development.component.html',
  styleUrls: ['./software-development.component.scss']
})
export class SoftwareDevelopmentComponent implements OnInit {
  @Input() language: string = LANGUAGES[0].languageShort;
  @Input() softwareDevelopments: any;
  cmsUrl = environment?.baseUrl;
  
  moveY: string = "";

  constructor(
    private requestsService: RequestsService,
    public utilsService: UtilsService,
    private router: Router
  ) {
    window.addEventListener('scroll', () => {
      var scrolled = window.scrollY;
      this.moveY = scrolled + 'px';
    });
  }

  ngOnInit() {
  }

  getSoftwareDevelopments() {
      ((devs: any) => {
        this.softwareDevelopments = devs.data.map((dev: any) => ({
          id: dev.id,
          title: dev.attributes.title,
          text: dev.attributes.text,
          icon: dev.attributes.icon,
          iconHover: dev.attributes.iconHover,
          redirectTo: dev.attributes.redirectTo,
          hovering: false 
        }));
      });
  }

  getIconUrl(software: any): string {
    return `${this.cmsUrl}${software.icon.data.attributes.url}`;
  }

  getHoverIconUrl(software: any): string {
    return `${this.cmsUrl}${software.iconHover.data.attributes.url}`;
  }

  onMouseEnter(software: any) {
    software.hovering = true;
  }

  onMouseLeave(software: any) {
    software.hovering = false;
  }

  goTo(path: string) {
    this.router.navigate([path]);
  }
}
