<div class="container" *ngIf="!coworkingForm">
    <div class="row my-5 align-items-center">
        <div class="col-12 col-xl-7">
            <h3>{{coworking?.title}}</h3>
            <p>{{coworking?.description}}</p>
            <h5>{{coworking?.iconsTitle}}</h5>
            <div class="row">
                <div class="col-4 col-md-3 text-center mt-4" *ngFor="let icon of coworking?.icons?.data; let i = index">
                    <img [height]="50" [width]="50" src="{{cmsUrl + icon?.attributes?.url}}" />
                    <h6 class="mt-3">{{icon?.attributes?.caption}}</h6>
                </div>
            </div>
        </div>
        <div class="col-12 col-xl-5 text-center">
            <img class="max-width cowork-img" [width]="645" [height]="645"
                src="{{cmsUrl + coworking?.image?.data?.attributes?.url}}" />
        </div>
    </div>



    <div class="row mx-5 my-3">
        <div class="col-12 col-sm-6 col-lg-4 col-xxl-2 my-3" *ngFor="let card of coworking?.priceCard">
            <div class="card shadow-sm">
                <div class="card-header" [ngClass]="card?.hasDiscount ? 'text-end' : 'text-center'">
                    <span class="text-center"> {{card?.title}}</span>
                    <span class="discount-badge yellow-col" *ngIf="card?.hasDiscount">{{card?.discount}}</span>
                </div>
                <div class="card-body text-center">
                    <h4 class="card-title">{{card?.price}}</h4>
                    <div *ngFor="let option of card?.options">
                        <p class="card-text py-1">{{option?.name}}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row mx-5">
        <div class="col">
            <span class="price-nr">{{coworking?.extraPrice}}</span>
            <span class="px-1 price-text">{{coworking?.extraPriceText}}</span>
        </div>
    </div>
    <div class="row my-5 text-center" *ngIf="coworking?.button">
        <div class="col">
            <button type="button" class="reserve-button" (click)="showForm()">{{coworking?.button?.btnText}}</button>
        </div>
    </div>

    <div class="row my-5 text-center">
        <div class="col">
            <h3>{{coworking?.titleSecond}}</h3>
        </div>
    </div>
    <div class="row px-1">
        <div class="col-12 bg-image"
            [ngStyle]="{'background-image':'url('+(cmsUrl + coworking?.imagesSecond?.data[0]?.attributes?.url)+')'}">
        </div>
    </div>
    <div class="row">
        <div class="col-12 col-md-6 col-xl-3 p-1" *ngFor="let image of coworking?.imagesSecond?.data.slice(1, 5)">
            <div class="bg-images" [ngStyle]="{'background-image':'url('+(cmsUrl + image?.attributes?.url)+')'}"></div>
        </div>
    </div>

    <div class="row my-5" *ngIf="coworking?.whyChooseUs">
        <div class=" col-12 offset-0 col-md-10 offset-md-1">
            <div class="row bg-light-blue shadow-sm border border-2 rounded-4 border-light"
                [ngStyle]="{'--bg-color': coworking?.whyChooseUs?.bgColor}">
                <div class="col-12 col-md-6 offset-0 col-lg-4 offset-lg-2 text-start text-padding">
                    <h5>{{coworking?.whyChooseUs?.title}}</h5>
                    <p class="font-small">{{coworking?.whyChooseUs?.description}}</p>
                    <div class="first-section" *ngFor="let item of coworking?.whyChooseUs?.benefits.slice(0, 3)">
                        <span class="weight-bold">{{item?.benefitTitle}}</span>
                        <span class="weight-normal">{{item?.benefitDescription}}</span>
                    </div>
                </div>
                <div class="col-12 col-md-6 col-xl-3 dark-blue-section text-light">
                    <div>
                        <div class="px-2" *ngFor="let item of coworking?.whyChooseUs?.benefits.slice(3, 8)">
                            <h6 class="pt-4">{{item?.benefitTitle}}</h6>
                            <h6>{{item?.benefitDescription}}</h6>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row mb-4" *ngIf="coworking?.whyChooseUs">
        <div class="col-12 col-md-6 col-xl-3 dark-blue-section-sm text-light">
            <div>
                <div class="px-2" *ngFor="let item of coworking?.whyChooseUs?.benefits.slice(3, 8)">
                    <h6 class="pt-4">{{item?.benefitTitle}}</h6>
                    <h6>{{item?.benefitDescription}}</h6>
                </div>
            </div>
        </div>
    </div>
</div>

<div #formContainer class="container text-center" *ngIf="coworkingForm">
    <div class="row justify-content-start align-items-center">
        <div class="col-auto">
            <button type="button" class="btn btn-primary-seconday" (click)="goBack()"><i
                    class="bi bi-arrow-left"></i></button>
        </div>
        <div class="col-12">
            <h1 class="primary-color">{{coworking?.formTitle}}</h1>
            <p class="grey-color-bold">{{coworking?.formDescription}}</p>
        </div>
    </div>
    <div class="row mt-5">
        <div class="col-12 offset-0 col-lg-8 offset-lg-2 col-xxl-6 offset-xxl-3 px-5">
            <form [formGroup]="coworkerInfo" class="text-start">
                <div class="form-group">
                    <label for="fullName">{{coworking?.coworkingForm?.nameSurname}}<span
                            class="text-danger">*</span></label>
                    <input type="text" class="form-control" id="fullName" formControlName="fullName">
                </div>

                <div class="form-group mt-4">
                    <label for="email">{{coworking?.coworkingForm?.email}}<span class="text-danger">*</span></label>
                    <input type="email" class="form-control" placeholder="mymail@gmail.com" id="email"
                        formControlName="email">
                </div>

                <div class="form-group mt-4">
                    <label for="phoneNumber">{{coworking?.coworkingForm?.phoneNr}}<span
                            class="text-danger">*</span></label>
                    <input type="tel" class="form-control" placeholder="+389 73 124 545" id="phoneNumber"
                        formControlName="phoneNumber">
                </div>

                <div class="form-group mt-4">
                    <label for="coworkingPlan">{{coworking?.coworkingForm?.coworkingPlan}}<span
                            class="text-danger">*</span></label>
                    <select class="form-select" name="coworkingPlan" id="coworkingPlan" formControlName="coworkingPlan"
                        aria-label="Coworking Plan">
                        <option selected disabled value="">{{'home.coworking-form.default-option' | translate}}</option>
                        <option *ngFor="let option of coworking?.coworkingPlanOptions" value="{{option?.name}}">
                            {{option?.name}}</option>
                    </select>
                </div>

                <div class="form-group mt-4">
                    <label for="startingDate">{{coworking?.coworkingForm?.startingDate}}<span
                            class="text-danger">*</span></label>
                    <input type="date" class="form-control" name="startingDate" formControlName="startingDate" />
                </div>

                <div class="form-group mt-4">
                    <label for="profession">{{coworking?.coworkingForm?.yourProfession}}<span
                            class="text-danger">*</span></label>
                    <input type="text" class="form-control" id="profession" formControlName="profession">
                </div>


                <div class="form-group mt-4">
                    <label for="note">{{coworking?.coworkingForm?.addNote}}</label>
                    <textarea id="note" rows="5" placeholder="{{'home.coworking-form.note-placeholder' | translate}}"
                        class="form-control form-control-lg" formControlName="note"></textarea>
                </div>
                <div class="form-group mt-4 form-check">
                    <input type="checkbox" class="form-check-input" id="terms" formControlName="terms">
                    <label class="form-check-label" for="terms">
                        {{coworking?.coworkingForm?.agree}}<a
                            [href]="cmsUrl + coworking?.coworkingForm?.termsAndConditionsFile?.data?.attributes?.url"
                            target="_blank" class="link-underline">
                            {{coworking?.coworkingForm?.termsAndConditions}}
                        </a><span class="text-danger">*</span>
                    </label>
                </div>
                <!-- <div class="checkbox mt-4">
                  <label><input type="checkbox"> I agree with the <a style="text-decoration: underline;">terms and conditions</a></label>
                </div> -->
                <button class="btn-primary-outline mt-4 btn"
                    (click)="submitForm(coworkerInfo)">{{'home.coworking-form.send' | translate}}</button>
            </form>
            <label class="pb-5 text-success">{{successFull}}</label>
            <label class="pb-5 text-danger" *ngIf="emptyMessage">{{emptyMessage}}</label>
            <label class="pb-5 text-danger">{{errorMessage}}</label>
        </div>
    </div>
</div>