<app-default-page
icon="file-earmark-plus"
[title]="('interactive-pdf-forms.title'|translate)"
[subTitle]="('interactive-pdf-forms.card-sub-title'|translate)" 
[subTitle0]="('interactive-pdf-forms.subTitle0'|translate)"
[subTitle1]="('interactive-pdf-forms.subTitle1'|translate)"
[categories]="('interactive-pdf-forms.webapp-categories'|translate)"
[items]="items"
[backgroundImg]="utilsService.backgrounds.interactive_pdf_forms"
[processes]="processes"
[references]="references"
[prices]="prices"

></app-default-page>