<div *ngIf="content">
  <!-- Go to top -->
  <i class="bi bi-arrow-up-circle-fill go-to-top" (click)="goToTop()"></i>

  <div class="position-relative">
    <div class="slideshow-container">
      <div class="mySlides sortware-marketing position-relative min-h-290" *ngFor="let slide of content.introduction; let i = index">
        <img [src]="cmsUrl + slide.image.data.attributes.url" 
             alt="{{slide.image.data.attributes.alternativeText}}" 
             class="sortware-marketing-background centered-image" 
             [width]="2917"
             [height]="1292"
             />
        <div class="we-are-text">
          <label class="primary-text">{{slide.title1}}</label>
          <div class="w-fit">
            <div class="typed-text secondary-text w-auto">{{slide.title2}}</div>
          </div>
          <label class="primary-text">{{slide.title3}}</label>
          <br />
          <button class="primary" (click)="goToOurServices()">
            <i class="bi bi-arrow-down"></i>
            {{slide.Button.btnText}}
          </button>
        </div>
      </div>
    </div>
    <br>
    <div class="txt-center">
      <span class="dot" *ngFor="let dot of content.introduction; let i = index"></span>
    </div>
    <app-project-in-mind [content]="content.projectInMind"></app-project-in-mind>
  </div>

  <!-- Other Components -->
  <div #section1 class="mt-100">
    <app-first-section [isolveComponent]="content.isolveComponent" [language]="language" ></app-first-section>
    <app-software-development [softwareDevelopments]="content.softwareDevelopments" [language]="language"></app-software-development>
  </div>
  <app-website-services [websiteServices]="content.websiteServices" [language]="language"></app-website-services>
  <app-graphic-design-services [graphicDesignServices]="content.graphicDesigns" [language]="language"></app-graphic-design-services>
  <app-work-from-home-boring [workFromHome]="content.workFromHome"></app-work-from-home-boring>
  <div id="works" #works>
    <app-selected-projects></app-selected-projects>
  </div>
  <app-products-stats [productsStats]="content.productsStats"></app-products-stats>
  <div id="techs" #techs>
    <app-technologies [technology]="content.technology"></app-technologies>
  </div>
  <app-conome-section [conomeSection]="content.conomeSection"></app-conome-section>
  <app-our-clients [ourClient]="content.ourClient"></app-our-clients>
  <app-price-overview [priceOverview]="content.priceOverview"></app-price-overview>
  <app-faq [faqs]="content.faq"></app-faq>
  <div id="socialmedia" #socialmedia>
    <app-social-media [socialMedia]="content.socialMedia"></app-social-media>
  </div>
</div>
