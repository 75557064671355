import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { RequestsService } from 'src/app/core/services/requests.service';
import { UtilsService } from 'src/app/core/services/utils.service';
import { environment } from 'src/app/environments';
import { MatDialog } from '@angular/material/dialog';
import { FullTextDialogComponent } from '../../full-text-dialog/full-text-dialog.component';

interface Project {
  id: number;
  image: string;
  alt: string;
  parent: number;
  text: string;
  fullText: string;
  buttonText : string;
}

@Component({
  selector: 'app-selected-projects',
  templateUrl: './selected-projects.component.html',
  styleUrls: ['./selected-projects.component.scss'],
 
})
export class SelectedProjectsComponent {
  ourProjects: Project[] = [];
  filteredProjects: Project[] = [];
  categories: any = [];
  selectedCategory = 1;
  selectedIndex = 0;
  projectsCarousel = false;
  titleDescription: any;

  constructor(
    private router: Router,
    private requestServices: RequestsService,
    public utilsService: UtilsService,
    public dialog: MatDialog
  ) {}

  async ngOnInit() {
    await this.getSelectedProjects();
    await this.getSelectedProjectsCategories();
  }

  filterProjects(category: number = this.selectedCategory) {
    this.filteredProjects = this.ourProjects.filter(
      (project) => project.parent === category
    );
  }

  goTo(path: string) {
    this.router.navigate([path]);
  }

  filterBy(filter: number) {
    this.selectedCategory = filter;
    this.filterProjects(filter);
  }

  async getSelectedProjects() {
    this.requestServices.getProjects().then((projects: any) => {
      this.titleDescription = projects.data.attributes;
      this.ourProjects = projects?.data?.attributes?.ourProjects
        ?.map((project: any) => {
          return {
            id: project.id,
            image: `${environment.baseUrl}${project?.image?.data?.attributes?.url}`,
            alt: project?.image?.data?.attributes?.alternativeText,
            parent: project?.category?.data?.id,
            text: project?.text, 
            fullText: project?.fullText,
          };
        })
        .sort((a: any, b: any) => a.id - b.id);
      this.filterProjects();
    });
  }

  async getSelectedProjectsCategories() {
    this.requestServices.getSelectedProjectsCategories().then((projects: any) => {
      this.categories = projects.data
        .map((project: any) => {
          return {
            id: project.id,
            title: project.attributes.name,
          };
        })
        .sort((a: any, b: any) => a.id - b.id);
    });
  }

  openCarousel(project: Project) {
    this.selectedIndex = this.ourProjects.findIndex((p) => p.image === project.image);
    this.projectsCarousel = true;
  }

  showMoreText(project: Project) {
    this.dialog.open(FullTextDialogComponent, {
      data: {
        fullText: project.fullText,
        buttonText : this.titleDescription.buttonText
      }
    });
  }

  onMouseOver(project: Project) {
    const element = document.querySelector(`.test[ng-reflect-ng-class*="${project.id}"]`);
    if (element) {
      requestAnimationFrame(() => {
        element.classList.add('hover-effect');
      });
    }
  }
  
  onMouseLeave(project: Project) {
    const element = document.querySelector(`.test[ng-reflect-ng-class*="${project.id}"]`);
    if (element) {
      requestAnimationFrame(() => {
        element.classList.remove('hover-effect');
      });
    }
  }
}
