import { Component, Input } from '@angular/core';
import { LANGUAGES } from 'src/app/core/constants';
import { RequestsService } from 'src/app/core/services/requests.service';
import { UtilsService } from 'src/app/core/services/utils.service';
import { environment } from 'src/app/environments';

@Component({
  selector: 'app-graphic-design-services',
  templateUrl: './graphic-design-services.component.html',
  styleUrls: ['./graphic-design-services.component.scss']
})
export class GraphicDesignServicesComponent {
  @Input() language: string = LANGUAGES[0].languageShort;
  @Input() graphicDesignServices: any;
  cmsUrl = environment?.baseUrl;
  moveY:string = "";

  //graphicDesignServices: any = []
  //chessKnight = `${environment.baseUrl}/uploads/chess_knight_tr_copy_560x560_27c9be39f6.png`;

  constructor(private requestService: RequestsService, private utilsService:UtilsService) {
    window.addEventListener('scroll', ()=> {
      var scrolled = window.scrollY;
      this.moveY = (scrolled * 0.2) + 'px'; // Adjust the multiplier for speed
    });
  }
  ngOnInit() {
    //this.getGraphicDesigns();
    this.graphicDesignServices?.cards.sort((a:any,b:any)=>a.id - b.id)
  }
  // ngOnChanges(changes: any) {
  //   if (changes.language.currentValue) {
  //     this.getGraphicDesigns();
  //   }
  // }

  getGraphicDesigns() {
    this.requestService.getGraphicDesigns()
      .then((designs: any) => {
        this.graphicDesignServices = designs.data.map((service: any) => {
          return {
            id: service.id,
            title: service.attributes.title,
            text: service.attributes.text,
            icon: service.attributes.icon,
            iconHover:service.attributes.iconHover,
            redirectTo: service.attributes.redirectTo,
            hovering:false
          }
        })
        .sort((a:any,b:any)=>a.id - b.id)
      })
  }

  getIconUrl(service: any): string {
    return `${this.cmsUrl}${service.icon.data.attributes.url}`;
  }
  
  getHoverIconUrl(service: any): string {
    return `${this.cmsUrl}${service.iconHover.data.attributes.url}`;
  }
  
  onMouseEnter(service: any) {
    service.hovering = true;
  }
  
  onMouseLeave(service: any) {
    service.hovering = false;
  }

  goTo(path:string){
    this.utilsService.goTo(path)
  }
}
