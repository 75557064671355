import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-prices',
  templateUrl: './prices.component.html',
  styleUrls: ['./prices.component.scss']
})
export class PricesComponent {

  @Input() prices: any = [];
  @Input() priceTitle: string = "";
  @Input() priceSubTitle: string = "";
  @Input() isPriceFrom: boolean = true;
  
 ngOnInit(){
 }
}
