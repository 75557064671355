import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './core/components/home/home.component';
import { WebApplicationsComponent } from './core/pages/software-development/web-applications/web-applications.component';
import { MobileAppsComponent } from './core/pages/software-development/mobile-apps/mobile-apps.component';
import { DesktopApplicationsComponent } from './core/pages/software-development/desktop-applications/desktop-applications.component';
import { WebDesignComponent } from './core/pages/professional-website-services/web-design/web-design.component';
import { MaintenanceComponent } from './core/pages/professional-website-services/maintenance/maintenance.component';
import { OnlineMarketingComponent } from './core/pages/professional-website-services/online-marketing/online-marketing.component';
import { LogoDesignComponent } from './core/pages/graphic-design-services/logo-design/logo-design.component';
import { BusinessCardsComponent } from './core/pages/graphic-design-services/business-cards/business-cards.component';
import { LetterheadComponent } from './core/pages/graphic-design-services/letterhead/letterhead.component';
import { BrochuresFlyersCatalogsComponent } from './core/pages/graphic-design-services/brochures-flyers-catalogs/brochures-flyers-catalogs.component';
import { InteractivePdfFormsComponent } from './core/pages/graphic-design-services/interactive-pdf-forms/interactive-pdf-forms.component';
import { MoreGraphicDesignServicesComponent } from './core/pages/graphic-design-services/more-graphic-design-services/more-graphic-design-services.component';
import { AboutUsComponent } from './core/pages/more/about-us/about-us.component';
import { CareersComponent } from './core/pages/more/careers/careers.component';
import { ContactUsComponent } from './core/pages/more/contact-us/contact-us.component';
import { CoworkingComponent } from './core/pages/coworking/coworking.component';

const routes: Routes = [
  { path: "web-applications", component: WebApplicationsComponent },
  { path: "mobile-apps", component: MobileAppsComponent },
  { path: "desktop-applications", component: DesktopApplicationsComponent },
  { path: "webdesign", component: WebDesignComponent },
  { path: "maintenance", component: MaintenanceComponent },
  { path: "online-marketing", component: OnlineMarketingComponent },
  { path: "logo-design", component: LogoDesignComponent },
  { path: "business-cards", component: BusinessCardsComponent },
  { path: "letterhead", component: LetterheadComponent },
  { path: "brochures-flyers-catalogs", component: BrochuresFlyersCatalogsComponent },
  { path: "interactive-pdf-forms", component: InteractivePdfFormsComponent },
  { path: "more-graphic-design-services", component: MoreGraphicDesignServicesComponent },
  { path: "coworking", component: CoworkingComponent },
  { path: "about-us", component: AboutUsComponent },
  { path: "careers", component: CareersComponent },
  { path: "careers/:id", component: CareersComponent },
  { path: "contact-us", component: ContactUsComponent },

  { path: "", component: HomeComponent },
  { path: "home", component: HomeComponent },
  { path: "home/:section", component: HomeComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top', anchorScrolling: 'disabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
