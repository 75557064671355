<div class="lets-talk-container">
  <div class="row mx-auto d-flex align-items-center h-100 text-center">
    <div class="col-sm-12 col-md-4 d-flex flex-row flex-wrap text-center justify-content-center">
      <div class="social-circle pointer mx-1" (click)="goTo(social?.url)" *ngFor="let social of socials">
        <i class="bi" [ngClass]="'bi-'+social?.icon"></i>
      </div>
    </div>
    <div class="col-sm-12 col-md-4">
      <h2 class="lets-talk">{{title}}</h2>
    </div>
    <div class="col-sm-12 col-md-4">
      <button class="slide-button" data-bs-toggle="modal" data-bs-target="#letsTalkModal">{{button?.btnText}}</button>
    </div>
  </div>
</div>
