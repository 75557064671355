import { Component } from '@angular/core';
import { RequestsService } from 'src/app/core/services/requests.service';
import { UtilsService } from 'src/app/core/services/utils.service';
import { environment } from 'src/app/environments';

@Component({
  selector: 'app-more-graphic-design-services',
  templateUrl: './more-graphic-design-services.component.html',
  styleUrls: ['./more-graphic-design-services.component.scss']
})
export class MoreGraphicDesignServicesComponent {

  items: any = [];
  processes: any = [];
  prices: any = [];
  references: any = [];
  cmsUrl = environment.baseUrl;

  constructor(private requestsService: RequestsService, public utilsService:UtilsService) { }
  ngOnInit() {
    this.getMoreGraphicDesignServices();
    this.getMoreGraphicDesignServicesProcesses();
    this.getReferences();
    this.utilsService.setTitle("More Graphic Design Services - iSolve")
    this.getSeo()
  }

  getMoreGraphicDesignServices() {
    this.requestsService.getMoreGraphicDesignServices()
      .then((apps: any) => {
        this.items = apps.data.map((app: any) => {
          return {
            id: app.id,
            title: app.attributes.title,
            text: app.attributes.text,
            iconUrl: `${this.cmsUrl}${app.attributes.icon.data[0].attributes.url}`,
            iconHoverUrl: `${this.cmsUrl}${app.attributes.iconHover.data[0].attributes.url}`
          }
        })
      })
  }

  
  getIconUrl(items: any): string {
    return `${this.cmsUrl}${items.attributes.icon.data.attributes.url}`;
  }
  
  getHoverIconUrl(items: any): string {
    return `${this.cmsUrl}${items.iconHover.data.attributes.url}`;
  }

  getMoreGraphicDesignServicesProcesses() {
    this.requestsService.getMoreGraphicDesignServicesProcesses()
      .then((apps: any) => {
        this.processes = apps.data.map((app: any) => app.attributes).sort((a: any, b: any) => a.id - b.id)
      })
  }


  getReferences() {
    this.requestsService.getSelectedProjects()
      .then((apps: any) => {
        this.references = apps.data.filter((app: any) => app.attributes.category.data.id === 7).map((app: any) => {
          return {
            image: `${environment.baseUrl}${app.attributes.image.data[0].attributes.url}`,
            alt: `${environment.baseUrl}${app.attributes.image.data[0].attributes.alternativeText}`
          }
        });
      })
  }
  
  getSeo() {
    this.requestsService.getSeo().then((seo: any) => { 
      this.utilsService.updateTags(seo.data.find((page: any) => page?.attributes?.page == 'MoreGraphicDesignServices'))
    })
  }

}
