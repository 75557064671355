<app-default-page
icon="tools"
[title]="('maintenance.title'|translate)"
[subTitle]="('maintenance.card-sub-title'|translate)" 
[subTitle0]="('maintenance.subTitle0'|translate)"
[subTitle1]="('maintenance.subTitle1'|translate)"
[categories]="('maintenance.webapp-categories'|translate)"
[items]="items"
[backgroundImg]="utilsService.backgrounds.maintenance"
[processes]="processes"
[prices]="prices"

></app-default-page>