<div class="text-center">
  <div class="pos-relative">
    <div class="dark-background bg-cover" [ngStyle]="{'background-image': 'url('+utilsService.backgrounds.contact+')'}">
      <div class="fron-text header-section">
        <label class="page-title">{{labels.contactTitle}}</label>
        <br />
        <div class="d-flex gap-3">
          <b class="text-secondary">{{labels.homeTitle | uppercase }}</b>
          <b class="text-light">/</b>
          <b class="text-light">{{ labels.contactTitle |translate| uppercase }}</b>
        </div>
      </div>
    </div>
  </div>

  <div>

    <!-- <h1 class=" fs-5 primary-text">{{'home.lets-talk.title'|translate}}</h1> -->
    <!-- <b class="disabled-text">{{'home.project-in-mind.modal.sub-title'|translate}}</b> -->
    <br />

    <form [formGroup]="userInfo" (ngSubmit)="submitForm()" class="mt-3 text-start">
      <label for="fullName"
        class="primary-text mt-5 mb-3">{{labels.fullName}}</label><label
        class="text-danger">*</label>
      <input id="fullName" type="text" class="form-control form-control-lg" formControlName="fullName" />

      <label for="email" class="primary-text  mt-5 mb-3">
        {{labels.email}}</label><label class="text-danger">*</label>
      <input id="email" type="email" class="form-control form-control-lg" formControlName="email" />

      <label for="subject" class="primary-text  mt-5 mb-3">
        {{ labels.subject }}
      </label>
      <label class="text-danger">*</label>
      <input id="subject" type="text" class="form-control form-control-lg" formControlName="subject" />

      <label for="budget" class="primary-text  mt-5 mb-3">
        {{ labels.budget }}
      </label>
      <select class="form-control form-control-lg" formControlName="budget">
        <option>Select budget</option>
        <option *ngFor="let budget of budgets" [value]="budget">{{budget}}</option>
      </select>

      <label for="message" class="primary-text  mt-5 mb-3">
        {{ labels.message }}
      </label>
      <label class="text-danger">*</label>
      <textarea id="message" rows="5" class="form-control form-control-lg" formControlName="message"></textarea>

      <button class="btn-primary-outline mt-5 btn-lg" type="submit">{{labels.buttonText}}</button>
    </form>

    <label class="text-success">{{successFull}}</label>
    <label class="text-danger">{{errorMessage}}</label>

  </div>


</div>