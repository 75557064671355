import { Component } from '@angular/core';
import { RequestsService } from 'src/app/core/services/requests.service';
import { UtilsService } from 'src/app/core/services/utils.service';
import { environment } from 'src/app/environments';

@Component({
  selector: 'app-letterhead',
  templateUrl: './letterhead.component.html',
  styleUrls: ['./letterhead.component.scss']
})
export class LetterheadComponent {

  items: any = [];
  processes: any = [];
  prices: any = [];
  references: any = [];
  cmsUrl = environment.baseUrl;

  constructor(private requestsService: RequestsService, public utilsService:UtilsService) { }
  ngOnInit() {
    this.getLetterhead();
    this.getLetterheadProcesses();
    this.getLetterheadPrices();
    this.utilsService.setTitle("Letterhead - iSolve")
    this.getSeo()
  }

  getLetterhead() {
    this.requestsService.getLetterhead()
      .then((apps: any) => {
        this.items = apps.data.map((app: any) => {
          return {
            id: app.id,
            title: app.attributes.title,
            text: app.attributes.text,
            iconUrl: `${this.cmsUrl}${app.attributes.icon.data[0].attributes.url}`,
            iconHoverUrl: `${this.cmsUrl}${app.attributes.iconHover.data[0].attributes.url}`
          }
        })
      })
  }

  
  getIconUrl(items: any): string {
    return `${this.cmsUrl}${items.attributes.icon.data.attributes.url}`;
  }
  
  getHoverIconUrl(items: any): string {
    return `${this.cmsUrl}${items.iconHover.data.attributes.url}`;
  }

  getLetterheadProcesses() {
    this.requestsService.getLetterheadProcesses()
      .then((apps: any) => {
        this.processes = apps.data.sort((a: any, b: any) => a.id - b.id).map((app: any) => app.attributes)
      })
  }

  getLetterheadPrices() {
    this.requestsService.getLetterheadPrices()
      .then((apps: any) => {
        this.prices = apps.data.map((price: any) => {
          return {
            id: price.id,
            title: price.attributes.title,
            subTitle: price.attributes.subTitle,
            price: price.attributes.price,
            isPopular: price.attributes.isPopular,
            options: price.attributes.options.split(";"),
            footer: price.attributes.footer,
            payment: price.attributes.payment,
          }
        }).sort((a: any, b: any) => a.id - b.id)
    })
  }  

  getSeo() {
    this.requestsService.getSeo().then((seo: any) => { 
      this.utilsService.updateTags(seo.data.find((page: any) => page?.attributes?.page == 'Letterhead'))
    })
  }

}
