import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-project-in-mind',
  templateUrl: './project-in-mind.component.html',
  styleUrls: ['./project-in-mind.component.scss']
})
export class ProjectInMindComponent implements OnInit {
  @Input() content: any;

  ngOnInit(): void {
  }

}
