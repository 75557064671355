import { Component, OnInit } from '@angular/core';
import { RequestsService } from 'src/app/core/services/requests.service';

@Component({
  selector: 'app-lets-talk',
  templateUrl: './lets-talk.component.html',
  styleUrls: ['./lets-talk.component.scss']
})
export class LetsTalkComponent implements OnInit {
  socials: any = [];
  title: string = '';
  button: any;

  constructor(private requestService: RequestsService) { }

  ngOnInit() {
    this.getSocials();
    this.getLetsTalk();
  }

  getLetsTalk() {
    this.requestService.getTalkSocials().subscribe((socials: any) => {
      this.title = socials?.data?.attributes?.title || 'Let\'s Talk';
      this.button = socials?.data?.attributes?.button || { btnText: 'Contact Us' };
    });
  }

  getSocials() {
    this.requestService.getSocials().subscribe((response: any) => {
      this.socials = response.data.map((item: any) => {
        return {
          id: item.id,
          icon: item.attributes.icon,
          url: item.attributes.url
        };
      });
    });
  }

  goTo(url: string) {
    window.open(url, "_blank");
  }
}
