import { Component, Renderer2, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { LANGUAGES } from './core/constants';
import { TranslateService } from '@ngx-translate/core';
import { IUserInfo } from './core/interfaces';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { RequestsService } from './core/services/requests.service';
import { UtilsService } from './core/services/utils.service';
import { environment } from './environments';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'isolve-website-frontend';
  // Get the progress bar elements
  progressContainer: HTMLElement | null = null;
  progressBar: HTMLElement | null = null;
  budgets: string[] = ["Low budget", "€1.000 - €2.000", "€2.000 - €5.000", "€5.000 - €10.000", "€10.000+"];
  labels:any = {};
  talk:string[] = [];
  userInfo = new FormGroup({
    fullName: new FormControl("", Validators.required),
    email: new FormControl("", Validators.required),
    subject: new FormControl("", Validators.required),
    budget: new FormControl(""),
    message: new FormControl("", Validators.required),
  }
  )
  successFull = "";
  errorMessage = "";

  constructor(
    private translate: TranslateService, 
    private requestService: RequestsService, 
    public utilsService: UtilsService,
    private renderer : Renderer2,
    @Inject(DOCUMENT) private document: Document,
    private titleService : Title
  ) {
    translate.addLangs(LANGUAGES.map(lang => lang.languageShort));
    translate.setDefaultLang(LANGUAGES[0].languageShort);
    translate.use(localStorage.getItem("language") || LANGUAGES[0].languageShort);
    if (localStorage.getItem("language") === null)
      localStorage.setItem("language", LANGUAGES[0].languageShort);
  }

  ngOnInit() {
    this.progressContainer = this.document.getElementById('progress-container');
    this.progressBar = this.document.getElementById('progress-bar');
    this.requestService.getCoworking()
    .subscribe((talk: any) => {
      this.talk = talk?.data?.attributes;
      this.getLabels();
    })   
    window.addEventListener('scroll', () => this.handleScroll());
    this.updateScrollProgress();
    this.getBackgrounds();
  }

  updateScrollProgress() {
    const scrollTop = this.document.documentElement.scrollTop || this.document.body.scrollTop;
    const scrollHeight = this.document.documentElement.scrollHeight - this.document.documentElement.clientHeight;
    const scrollProgress = (scrollTop / scrollHeight) * 100;
    this.progressBar!.style.width = scrollProgress + '%';
  }


  handleScroll() {
    this.updateScrollProgress();
    const scrollTop = this.document.documentElement.scrollTop || this.document.body.scrollTop;
    this.progressContainer!.style.display = scrollTop > 20 ? 'block' : 'none';
  }

  submitForm() {
    this.successFull = "";
    this.errorMessage = "";

    if (this.userInfo.valid) {
      const test = this.userInfo.value
      this.requestService.addTalk(test as IUserInfo)
        .then((result: any) => {
          this.userInfo.reset();
          this.successFull = this.translate.instant("home.lets-talk.success-message-sent")
          setTimeout(() => {
            this.successFull = "";
          }, 5000);
        })
        .catch((err: any) => {
          this.errorMessage = `${this.translate.instant("home.lets-talk.error-message-sent")}: ${err.error.error.message}`
          setTimeout(() => {
            this.errorMessage = "";
          }, 5000);
        })
    }
  }

  getBackgrounds() {
    this.requestService.getBackgrounds()
      .then((backgrounds: any) => {
        let attributes = backgrounds?.data?.attributes;
        for (let attr of Object.keys(attributes)) {
          attributes[attr] = typeof attributes[attr] === "string" ? attributes[attr] : `${environment.baseUrl}${attributes[attr]?.data?.attributes?.url}`;
        }
        this.utilsService.backgrounds = attributes;
      })
  }

  get languageSelected() {
    return localStorage.getItem("language") || LANGUAGES[0].languageShort;
  }

  getLabels() {
    this.requestService.getContactLabels()
      .subscribe((res: any) => {
        if (Array.isArray(res.data)) {
          this.labels = res.data.reduce((acc: any, response: any) => {
            acc[response.attributes.key] = response.attributes.value;
            return acc;
          }, {});
        } else if (res.data.attributes) {
          this.labels = Object.keys(res.data.attributes).reduce((acc: any, key: string) => {
            acc[key] = res.data.attributes[key];
            return acc;
          }, {});
        }
      });
  }


  updateLanguage(newLanguage: string) {
    localStorage.setItem("language", newLanguage);    
    location.reload();
  }

  get languages() {
    return LANGUAGES.map(language => language.languageShort);
  }
}
