import { Component, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { RequestsService } from '../../services/requests.service';
import { environment } from 'src/app/environments';
import { UtilsService } from '../../services/utils.service';
import { ViewportScroller } from '@angular/common';
import { Router, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-coworking',
  templateUrl: './coworking.component.html',
  styleUrls: ['./coworking.component.scss']
})
export class CoworkingComponent {
  @ViewChild('formContainer') formContainer!: ElementRef;

  public coworking: any;
  coworkingForm: boolean = false;
  cmsUrl = environment?.baseUrl;

  coworkerInfo = new FormGroup({
    fullName: new FormControl("", Validators.required),
    email: new FormControl("", Validators.required),
    phoneNumber: new FormControl('', [Validators.required]),
    coworkingPlan: new FormControl("", Validators.required),
    startingDate: new FormControl("", Validators.required),
    profession: new FormControl("", Validators.required),
    terms: new FormControl(false, Validators.requiredTrue),
    note: new FormControl(""),
  });

  successFull = "";
  errorMessage = "";
  emptyMessage = "";

  constructor(private translate: TranslateService,
    private requestsService: RequestsService,
    private utilsService: UtilsService,
    private router: Router,
    private viewportScroller: ViewportScroller,
    private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.requestsService.getCoworking()
      .subscribe((coworking: any) => {
        this.coworking = coworking?.data?.attributes;
      });



    // this.router.events.subscribe(event => {
    //   if (event instanceof NavigationEnd) {
    //     this.viewportScroller.scrollToPosition([0, 0]);
    //   }
    // });
  }

  submitForm(form: FormGroup) {
    this.successFull = "";
    this.errorMessage = "";
    this.emptyMessage = "";

    if (form.valid) {
      const data = form.value;
      this.requestsService.getCoworkerInfo(data)
        .then((coworker: any) => {
          this.coworkerInfo.reset();
          this.successFull = this.translate.instant("home.lets-talk.success-message-sent");
          setTimeout(() => {
            this.successFull = "";
            this.coworkingForm = false;
          }, 5000);
        })
        .catch((err: any) => {
          this.errorMessage = `${this.translate.instant("home.lets-talk.error-message-sent")}: ${err.error.error.message}`;
          setTimeout(() => {
            this.errorMessage = "";
          }, 5000);
        });
    } else {
      this.emptyMessage = `${this.translate.instant("home.lets-talk.empty-message")}`;
      setTimeout(() => {
        this.emptyMessage = "";
      }, 5000);
    }
  }

  getSeo() {
    this.requestsService.getSeo().then((seo: any) => {
      this.utilsService.updateTags(seo.data.find((page: any) => page?.attributes?.page == 'Coworking'));
    });
  }

  goBack() {
    this.coworkingForm = false;
  }

  showForm() {
    this.coworkingForm = true;
    this.cdr.detectChanges();
    setTimeout(() => {
      if (this.formContainer && this.formContainer.nativeElement) {
        this.formContainer.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }, 0);
  }
}
