<div *ngIf="career !== null">
  
  <!-- Banner -->
  <div class="banner">
    <h6>{{'careers.developer'|translate | uppercase}}, JOBS</h6>
    <label class="job-title">{{career?.title}}</label>
  </div>

  <div class="job">
    <!-- Description -->
    <div>
      <h2 class="primary-title text-center">{{'careers.description' |translate}}</h2>
      <label class="disabled-text">{{career?.description}}</label>
    </div>

    <!-- Right fit -->
    <div class="row mx-auto mt-3" *ngIf="career.right_fit.length">
      <div class="col-12">
        <h2 class="primary-title">{{'careers.right-fit' |translate}}</h2>
        <ul>
          <li class="disabled-text" *ngFor="let fit of career.right_fit">{{fit}}</li>
        </ul>
      </div>
    </div>

    <!-- Responsabilities  -->
    <div class="row mx-auto">
      <div class="col-sm-12 col-md-6" *ngIf="career.responsibilities.length">
        <h2 class="primary-title">{{'careers.responsabilities' |translate}}</h2>
        <ul>
          <li class="disabled-text" *ngFor="let fit of career.responsibilities">{{fit}}</li>
        </ul>
      </div>
      <!-- </div> -->

      <!-- Requirements  -->
      <!-- <div class="row mx-auto"> -->
      <div class="col-sm-12 col-md-6" *ngIf="career.requirements.length">
        <h2 class="primary-title">{{'careers.requirements' |translate}}</h2>
        <ul>
          <li class="disabled-text" *ngFor="let fit of career.requirements">{{fit}}</li>
        </ul>
      </div>
      <!-- </div> -->

      <!-- Benefits -->
      <!-- <div class="row mx-auto"> -->
      <div class="col-sm-12 col-md-6" *ngIf="career.benefits.length">
        <h2 class="primary-title">{{'careers.benefits' |translate}}</h2>
        <ul>
          <li class="disabled-text" *ngFor="let fit of career.benefits">{{fit}}</li>
        </ul>
      </div>
      <!-- </div> -->

      <!-- Benefits -->
      <!-- <div class="row mx-auto"> -->
      <div class="col-sm-12 col-md-6" *ngIf="career.nice_to_have.length">
        <h2 class="primary-title">{{'careers.nice_to_have' |translate}}</h2>
        <ul>
          <li class="disabled-text" *ngFor="let fit of career.nice_to_have">{{fit}}</li>
        </ul>
      </div>
    </div>

    <hr class="color-secondary"/>

    <!-- Apply now section -->
<section>
  <h2 class="primary-title text-center">{{ 'careers.apply-now' | translate }}</h2>
  <form (ngSubmit)="sendJobApplication()" [formGroup]="jobApplication">
    <!-- Name Surname * -->
    <label for="name">{{ 'careers.name-surname' | translate }}</label>
    <input type="text" class="form-control form-control-sm mb-2" formControlName="name" />

    <!-- Your e-mail* * -->
    <label for="email">{{ 'careers.your-email' | translate }}</label>
    <input type="email" class="form-control form-control-sm mb-2" formControlName="email" />

    <!-- Your phone number * -->
    <label for="phone">{{ 'careers.phone-number' | translate }}</label>
    <input type="tel" class="form-control form-control-sm mb-2" formControlName="phone" />

    <!-- Applying for* -->
    <label for="applying">{{ 'careers.applying-for' | translate }}</label>
    <input type="text" class="form-control form-control-sm mb-2" [value]="career.title" disabled />

    <!-- Attach your CV -->
    <label for="cv">{{ 'careers.attach-cv' | translate }}</label>
    <input type="file" class="form-control form-control-sm mb-2" (change)="onFileSelected($event)" />

    <!-- Or paste your link -->
    <label for="link">{{ 'careers.paste-link' | translate }}</label>
    <input type="text" class="form-control form-control-sm mb-2" formControlName="link" />

    <!-- Days required to start, if chosen? -->
    <label for="days">{{ 'careers.notice-period' | translate }}</label>
    <input type="number" class="form-control form-control-sm mb-2" formControlName="days" />

    <!-- Your message -->
    <label for="message">{{ 'careers.message' | translate }}</label>
    <textarea rows="10" class="form-control form-control-sm mb-2" formControlName="message"></textarea>

    <button class="primary-btn" type="submit">{{ 'careers.submit' | translate }}</button>
    <br />
    <label class="text-danger">{{ messageError }}</label>
    <label class="text-success">{{ messageSuccess }}</label>
  </form>

      <div class="share-button" data-href="your-page-url" data-layout="button" data-size="large">
        <a target="_blank" [href]="currentPath" class="fb-xfbml-parse-ignore">
          <i class="bi bi-facebook"></i>
          Facebook</a>
      </div>


    </section>

  </div>

</div>