<div *ngIf="technology" class="techs-container py-5">

  <div class="default-padding">
    <div class="text-center">
      <h2 class="primary-title">{{technology?.title}}</h2>
    </div>

    <div class="row mx-auto text-center techs">
      <div class="col-sm-12 col-md-6 " *ngFor="let technology of technology?.category; let i=index" [ngClass]="getBorders(i)">
        <h4 class="primary-text my-5">{{technology.name}}</h4>
        <div class="row align-items-center">
          <div class="col-4" *ngFor="let children of technology?.tech">
            <img [src]="cmsUrl + children?.image?.data?.attributes?.url" [alt]="children?.image?.data?.attributes?.alternativeText" 
              [width]="173" [height]="90" class="mb-3" class="img-tech" /><br />
            <div class="h-100">{{children.name}}</div>
          </div>
        </div>
      </div>
    </div>

    <div class="text-center my-5 ">
      <button class="btn-primary-seconday" data-bs-toggle="modal" data-bs-target="#letsTalkModal">{{technology?.button?.btnText}}</button>
    </div>
  </div>
</div>