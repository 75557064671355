import { Component, Input } from '@angular/core';
import { LANGUAGES } from 'src/app/core/constants';
import { RequestsService } from 'src/app/core/services/requests.service';
import { UtilsService } from 'src/app/core/services/utils.service';
import { environment } from 'src/app/environments';

@Component({
  selector: 'app-website-services',
  templateUrl: './website-services.component.html',
  styleUrls: ['./website-services.component.scss']
})
export class WebsiteServicesComponent {
@Input() language:string = LANGUAGES[0].languageShort;
@Input() websiteServices: any;
cmsUrl = environment?.baseUrl;


//chessRok = `${environment.baseUrl}/uploads/chess_rok_tr_copy_0caf3e10ed.png`;
moveY:string = "";
//websiteServices:any = []

constructor(private requestService:RequestsService, private utilsService:UtilsService){
  window.addEventListener('scroll', ()=> {
    var scrolled = window.scrollY;
    this.moveY = (scrolled * 0.1) + 'px'; // Adjust the multiplier for speed
  });
}
ngOnInit(){
  //this.getWebsiteServices();
}
// ngOnChanges(changes:any){
// if(changes.language.currentValue){
//   this.getWebsiteServices();
// }
// }

getWebsiteServices(){
  this.requestService.getWebsiteServices()
  .then((services:any)=>{
    this.websiteServices = services.data.map((service:any)=>{
      return {
        id: service.id,
        title: service.attributes.title,
        text: service.attributes.text,
        icon: service.attributes.icon,
        iconHover:service.attributes.iconHover,
        redirectTo: service.attributes.redirectTo,
        hovering:false
      }
    })
  })
}

getIconUrl(service: any): string {
  return `${this.cmsUrl}${service.icon.data.attributes.url}`;
}

getHoverIconUrl(service: any): string {
  return `${this.cmsUrl}${service.iconHover.data.attributes.url}`;
}

onMouseEnter(service: any) {
  service.hovering = true;
}

onMouseLeave(service: any) {
  service.hovering = false;
}

goTo(path:string){
  this.utilsService.goTo(path)
}

}
