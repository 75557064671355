<div>
  <!-- Desktop -->
  <div class="menu-container" id="menucontainer">
    <div class="close-container d-sm-block d-lg-none">
      <i class="bi bi-x" (click)="toggleMenu()"></i>
    </div>
    <!-- Logo -->
    <img [src]="logoImg" [width]="1347" [height]="435" alt="iSolve logo" width="120"
      class="margin-right w-10 mb-19 pointer d-none d-lg-block mr-3" (click)="goTo('')" />

    <!-- Moto -->
    <div class="menu-label margin-right menu-item pointer" (click)="goTo('')">Where code and art meet!</div>

    <!-- Menu items -->
    <div class="menu-label" *ngFor="let menuItem of menuItems">
      <div class="dropdown margin-right menu-item">
        <a class="btn btn-light dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
          {{menuItem.title}}
        </a>
        <ul class="dropdown-menu">
          <li *ngFor="let action of menuItem.children">
            <a class="dropdown-item" [href]="action.attributes.redirectTo"> {{action?.attributes.title}} </a>
          </li>
        </ul>
      </div>
    </div>

    <!-- Languages -->
    <div class="languages-container d-sm-none d-lg-block">
      <label *ngFor="let language of languages" class="margin-right pointer menu-item"
        [ngClass]="languageSelected === language ? 'selected' : ''" (click)="updateLanguage(language)">{{language |
        uppercase}}</label>
    </div>

    <!-- Social -->
    <div class="mx-5 d-sm-none d-lg-block">
      <i *ngFor="let social of socials" class="bi margin-right pointer" [ngClass]="'bi-'+social.icon"
        (click)="goTo(social.url)"></i>
    </div>
  </div>

  <!-- Mobile -->
  <div class="menu-container-mobile" id="menucontainermobile">
    <!-- Logo -->
    <img [src]="logoImgMobile" alt="iSolve logo" [height]="45" [width]="40"
      class="margin-right pointer d-sm-block d-lg-none" (click)="goTo('')" />

    <i class="bi bi-list d-sm-block d-lg-none" (click)="toggleMenu()"></i>
  </div>

  <!-- Mobile menu items -->
  <div *ngIf="isMobileMenuVisible" class="mobile-menu-container mt-6">
    <div class="mobile-menu-label" *ngFor="let menuItem of menuItems; let i=index">
      <div class="mobile-menu-item" (click)="toggleDropdown(i)">
        <div class="mobile-main-item">
          {{ menuItem.title }}
        </div>
        <div class="down-icon">
          <img [src]="isDropdownOpen[i] ? '/assets/up.svg' : '/assets/down.svg'">
        </div>
        <div *ngIf="isDropdownOpen[i]" class="mobile-dropdown-content">
          <div class="mobile-dropdown-group">
            <div *ngFor="let action of menuItem.children">
              <div class="mobile-subitem" (click)="goTo(action.attributes.redirectTo)">
                {{ action.attributes.title }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Mobile Languages -->
    <div class="d-flex justify-content-center align-items-end mb-4 mt-3">
      <label *ngFor="let language of languages" class="margin-right pointer mobile-language-item fs-3"
        [ngClass]="languageSelected === language ? 'selected' : ''" (click)="updateLanguage(language)">{{language |
        uppercase}}</label>
    </div>

    <!--Mobile Social -->
    <div class="d-flex justify-content-center mt-lg-3">
      <i *ngFor="let social of socials" class="bi margin-right mobile-social-item pointer fs-3"
        [ngClass]="'bi-'+social.icon" (click)="goTo(social.url)"></i>
    </div>
  </div>
</div>