import { Component } from '@angular/core';
import { RequestsService } from 'src/app/core/services/requests.service';
import { UtilsService } from 'src/app/core/services/utils.service';
import { environment } from 'src/app/environments';

interface DesktopApplications {
  id: number,
  title: string,
  text: string,
  icon: string,
  iconHover: string
}

@Component({
  selector: 'app-desktop-applications',
  templateUrl: './desktop-applications.component.html',
  styleUrls: ['./desktop-applications.component.scss']
})
export class DesktopApplicationsComponent {
  items: DesktopApplications[] = [];
  processes: string[] = [];
  references: string[] = [];
  desktopAppTitle = '';
  desktopAppText = '';
  cmsUrl = environment.baseUrl;

  constructor(private requestsService: RequestsService, public utilsService: UtilsService) { }
  ngOnInit() {
    this.getDesktopApplications();
    this.getProcesses();
    this.utilsService.setTitle("Desktop Applications - iSolve");
    this.getDesktopAppText();
    this.getSeo();
  }

  getDesktopApplications() {
    this.requestsService.getDesktopApplications()
      .then((apps: any) => {
        this.items = apps.data.map((app: any) => {
          return {
            id: app.id,
            title: app.attributes.title,
            text: app.attributes.text,
            iconUrl: `${this.cmsUrl}${app.attributes.icon.data[0].attributes.url}`,
            iconHoverUrl: `${this.cmsUrl}${app.attributes.iconHover.data[0].attributes.url}`
          }
        })
      })
  }


  getIconUrl(items: any): string {
    return `${this.cmsUrl}${items.attributes.icon.data.attributes.url}`;
  }

  getHoverIconUrl(items: any): string {
    return `${this.cmsUrl}${items.iconHover.data.attributes.url}`;
  }

  getProcesses() {
    this.requestsService.getDesktopProcesses()
      .then((apps: any) => {
        this.processes = apps.data.map((app: any) => app.attributes).sort((a: any, b: any) => a.id - b.id)
      })
  }

  getDesktopAppText() {
    this.requestsService.getDesktopAppText()
      .subscribe((response: any) => {
        this.desktopAppTitle = response.data.attributes.title;
        this.desktopAppText = response.data.attributes.text;
      });
  }

  getSeo() {
    this.requestsService.getSeo().then((seo: any) => {
      this.utilsService.updateTags(seo.data.find((page: any) => page?.attributes?.page == 'DesktopApplications'))
    })
  }

}
