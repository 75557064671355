import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/app/environments';
import { IMenuItem, IUserInfo } from '../interfaces';
import { UtilsService } from './utils.service';
import { firstValueFrom, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RequestsService {

  constructor(private http: HttpClient) { }

  getMenuItems() {
    const locale = localStorage.getItem("language");
    return this.http.get<IMenuItem[]>(`${environment.baseUrl}/api/menus?populate=*&locale=${locale}`)
  }

  getSocials() {
    const locale = localStorage.getItem("language");
    return this.http.get<IMenuItem[]>(`${environment.baseUrl}/api/socials?populate=*`)
  }

  async getSelectedProjectsCategories() {
    const locale = localStorage.getItem("language");
    return firstValueFrom(this.http.get<IMenuItem[]>(`${environment.baseUrl}/api/selected-projects-categories?populate=deep&locale=${locale}`));
  }

  getSelectedProjects() {
    const locale = localStorage.getItem("language");
    return firstValueFrom(this.http.get(`${environment.baseUrl}/api/selected-projects?populate=deep&locale=${locale}`));
  }

  getHomePage() {
    const locale = localStorage.getItem("language") ;
    return this.http.get<{ data: any }>(`${environment.baseUrl}/api/home-page?populate=deep&locale=${locale}`);
  }
  

  getProjects() {
    const locale = localStorage.getItem("language");
    return firstValueFrom(this.http.get(`${environment.baseUrl}/api/our-selected-project?populate=deep&locale=${locale}`));
  }

  getTalkSocials() {
    const locale = localStorage.getItem("language");
    return this.http.get(`${environment.baseUrl}/api/lets-talk?populate=deep&locale=${locale}`);
  }

  getWebAppText() {
    const locale = localStorage.getItem("language");
    return this.http.get(`${environment.baseUrl}/api/web-design-text?populate=deep&locale=${locale}`);
  }

  getMobileAppText(){
    const locale = localStorage.getItem("language");
    return this.http.get(`${environment.baseUrl}/api/mobile-app-text?populate=deep&locale=${locale}`);
  }

  getDesktopAppText(){
    const locale = localStorage.getItem("language");
    return this.http.get(`${environment.baseUrl}/api/desktop-app-text?populate=deep&locale=${locale}`);
  }

   getContactLabels(){
    const locale = localStorage.getItem("language");
    return this.http.get(`${environment.baseUrl}/api/contact?populate=deep&locale=${locale}`);
  }

  getCoworking() {
    const locale = localStorage.getItem("language");
    return this.http.get(`${environment.baseUrl}/api/coworking?populate=deep&locale=${locale}`);
  }

  getTechnologyCategories() {
    const locale = localStorage.getItem("language");
    return firstValueFrom(this.http.get(`${environment.baseUrl}/api/technology-categories?populate=deep&locale=${locale}`));
  }

  getTechnology() {
    const locale = localStorage.getItem("language");
    return firstValueFrom(this.http.get(`${environment.baseUrl}/api/technologies?populate=deep&locale=${locale}`));
  }

  getOurClients() {
    const locale = localStorage.getItem("language");
    return firstValueFrom(this.http.get(`${environment.baseUrl}/api/our-clients?populate=deep&locale=${locale}`));
  }

  getPrices() {
    const locale = localStorage.getItem("language");
    return firstValueFrom(this.http.get(`${environment.baseUrl}/api/prices?populate=deep&locale=${locale}`));
  }

  getFaqs() {
    const locale = localStorage.getItem("language");
    return firstValueFrom(this.http.get(`${environment.baseUrl}/api/faqs?populate=deep&locale=${locale}`));
  }

  async getSoftwareDevelopments() {
    const locale = localStorage.getItem("language");
    return firstValueFrom(this.http.get<IMenuItem[]>(`${environment.baseUrl}/api/software-developments?populate=deep&locale=${locale}`));
  }

  async getWebsiteServices() {
    const locale = localStorage.getItem("language");
    return firstValueFrom(this.http.get<IMenuItem[]>(`${environment.baseUrl}/api/website-services?populate=deep&locale=${locale}`));
  }

  async getGraphicDesigns() {
    const locale = localStorage.getItem("language");
    return firstValueFrom(this.http.get<IMenuItem[]>(`${environment.baseUrl}/api/graphic-designs?populate=deep&locale=${locale}`));
  }

  async addTalk(userInfo: IUserInfo) {
    return firstValueFrom(this.http.post(`${environment.baseUrl}/api/send-mail`, {
      to: environment.email,
      subject: "Let's talk.",
      html: `
      <div>
      Name: ${userInfo.fullName}<br/>
      Email: ${userInfo.email}<br/>
      Subject: ${userInfo.subject}<br/>
      Budget: ${userInfo.budget}<br/>
      Message: ${userInfo.message}<br/>
      </div>
      `
    }));

  }

  async getWebApplications() {
    const locale = localStorage.getItem("language");
    return firstValueFrom(this.http.get(`${environment.baseUrl}/api/web-applications?populate=deep&locale=${locale}`));
  }

  async getSeo() {
    const locale = localStorage.getItem("language");
    return firstValueFrom(this.http.get(`${environment.baseUrl}/api/seos?populate=deep&locale=${locale}`));
  }

  async getProcesses() {
    const locale = localStorage.getItem("language");
    return firstValueFrom(this.http.get(`${environment.baseUrl}/api/web-application-processes?populate=deep&locale=${locale}`));
  }

  // Mobile
  async getMobileApplications() {
    const locale = localStorage.getItem("language");
    return firstValueFrom(this.http.get(`${environment.baseUrl}/api/mobile-app-categories?populate=deep&locale=${locale}`));
  }
  async getMobileProcesses() {
    const locale = localStorage.getItem("language");
    return firstValueFrom(this.http.get(`${environment.baseUrl}/api/mobile-app-processes?populate=deep&locale=${locale}`));
  }
  async getMobilePrices() {
    const locale = localStorage.getItem("language");
    return firstValueFrom(this.http.get(`${environment.baseUrl}/api/mobile-app-prices?populate=deep&locale=${locale}`));
  }

  // Desktop
  async getDesktopApplications() {
    const locale = localStorage.getItem("language");
    return firstValueFrom(this.http.get(`${environment.baseUrl}/api/desktop-applications?populate=deep&locale=${locale}`));
  }
  async getDesktopProcesses() {
    const locale = localStorage.getItem("language");
    return firstValueFrom(this.http.get(`${environment.baseUrl}/api/desktop-app-processes?populate=deep&locale=${locale}`));
  }

  // WebDesign
  async getWebDesign() {
    const locale = localStorage.getItem("language");
    return firstValueFrom(this.http.get(`${environment.baseUrl}/api/web-design-categories?populate=deep&locale=${locale}`));
  }
  async getWebDesignProcesses() {
    const locale = localStorage.getItem("language");
    return firstValueFrom(this.http.get(`${environment.baseUrl}/api/web-design-processes?populate=deep&locale=${locale}`));
  }
  async getWebDesignPrices() {
    const locale = localStorage.getItem("language");
    return firstValueFrom(this.http.get(`${environment.baseUrl}/api/web-design-prices?populate=deep&locale=${locale}`));
  }

  // Maintenance
  async getMaintenance() {
    const locale = localStorage.getItem("language");
    return firstValueFrom(this.http.get(`${environment.baseUrl}/api/maintenance-categories?populate=deep&locale=${locale}`));
  }
  async getMaintenanceProcesses() {
    const locale = localStorage.getItem("language");
    return firstValueFrom(this.http.get(`${environment.baseUrl}/api/maintenance-processes?populate=deep&locale=${locale}`));
  }
  async getMaintenancePrices() {
    const locale = localStorage.getItem("language");
    return firstValueFrom(this.http.get(`${environment.baseUrl}/api/maintenance-prices?populate=deep&locale=${locale}`));
  }

  // Online marketing
  async getOnlineMarketing() {
    const locale = localStorage.getItem("language");
    return firstValueFrom(this.http.get(`${environment.baseUrl}/api/online-marketing-categories?populate=deep&locale=${locale}`));
  }
  async getOnlineMarketingProcesses() {
    const locale = localStorage.getItem("language");
    return firstValueFrom(this.http.get(`${environment.baseUrl}/api/online-marketing-processes?populate=deep&locale=${locale}`));
  }
  async getOnlineMarketingPrices() {
    const locale = localStorage.getItem("language");
    return firstValueFrom(this.http.get(`${environment.baseUrl}/api/online-marketing-prices?populate=deep&locale=${locale}`));
  }

  // Logo Design
  async getLogoDesign() {
    const locale = localStorage.getItem("language");
    return firstValueFrom(this.http.get(`${environment.baseUrl}/api/logo-design-categories?populate=deep&locale=${locale}`));
  }
  async getLogoDesignProcesses() {
    const locale = localStorage.getItem("language");
    return firstValueFrom(this.http.get(`${environment.baseUrl}/api/logo-design-processes?populate=deep&locale=${locale}`));
  }

  getLocalizedCareerContent(id: string, locale: string): Observable<any> {
    return this.http.get(`${environment.baseUrl}/api/careers/${id}?populate=deep&locale=${locale}`);
  }

  async getLogoDesignPrices() {
    const locale = localStorage.getItem("language");
    return firstValueFrom(this.http.get(`${environment.baseUrl}/api/logo-design-prices?populate=deep&locale=${locale}`));
  }

  // Business Cards
  async getBusinessCards() {
    const locale = localStorage.getItem("language");
    return firstValueFrom(this.http.get(`${environment.baseUrl}/api/business-cards-categories?populate=deep&locale=${locale}`));
  }
  async getBusinessCardsProcesses() {
    const locale = localStorage.getItem("language");
    return firstValueFrom(this.http.get(`${environment.baseUrl}/api/business-cards-processes?populate=deep&locale=${locale}`));
  }
  async getBusinessCardsPrices() {
    const locale = localStorage.getItem("language");
    return firstValueFrom(this.http.get(`${environment.baseUrl}/api/business-cards-prices?populate=deep&locale=${locale}`));
  }

  // Letterhead
  async getLetterhead() {
    const locale = localStorage.getItem("language");
    return firstValueFrom(this.http.get(`${environment.baseUrl}/api/letterhead-categories?populate=deep&locale=${locale}`));
  }
  async getLetterheadProcesses() {
    const locale = localStorage.getItem("language");
    return firstValueFrom(this.http.get(`${environment.baseUrl}/api/letterhead-processes?populate=deep&locale=${locale}`));
  }
  async getLetterheadPrices() {
    const locale = localStorage.getItem("language");
    return firstValueFrom(this.http.get(`${environment.baseUrl}/api/letterhead-prices?populate=deep&locale=${locale}`));
  }

  // BrochuresFlyersCatalogs
  async getBrochuresFlyersCatalogs() {
    const locale = localStorage.getItem("language");
    return firstValueFrom(this.http.get(`${environment.baseUrl}/api/brochures-flyers-catalogs-categories?populate=deep&locale=${locale}`));
  }
  async getBrochuresFlyersCatalogsProcesses() {
    const locale = localStorage.getItem("language");
    return firstValueFrom(this.http.get(`${environment.baseUrl}/api/brochures-flyers-catalogs-processes?populate=deep&locale=${locale}`));
  }
  async getBrochuresFlyersCatalogsPrices() {
    const locale = localStorage.getItem("language");
    return firstValueFrom(this.http.get(`${environment.baseUrl}/api/brochures-flyers-catalogs-prices?populate=deep&locale=${locale}`));
  }

  // PDF Forma Interaktive
  async getInteractivePdfForms() {
    const locale = localStorage.getItem("language");
    return firstValueFrom(this.http.get(`${environment.baseUrl}/api/interactive-pdf-forms-categories?populate=deep&locale=${locale}`));
  }
  async getInteractivePdfFormsProcesses() {
    const locale = localStorage.getItem("language");
    return firstValueFrom(this.http.get(`${environment.baseUrl}/api/interactive-pdf-forms-processes?populate=deep&locale=${locale}`));
  }
  async getInteractivePdfFormsPrices() {
    const locale = localStorage.getItem("language");
    return firstValueFrom(this.http.get(`${environment.baseUrl}/api/interactive-pdf-forms-prices?populate=deep&locale=${locale}`));
  }

  // More graphic design services
  async getMoreGraphicDesignServices() {
    const locale = localStorage.getItem("language");
    return firstValueFrom(this.http.get(`${environment.baseUrl}/api/more-graphic-design-services-categories?populate=deep&locale=${locale}`));
  }
  async getMoreGraphicDesignServicesProcesses() {
    const locale = localStorage.getItem("language");
    return firstValueFrom(this.http.get(`${environment.baseUrl}/api/more-graphic-design-services-processes?populate=deep&locale=${locale}`));
  }

  // Careers

  async getCareers() {
    const locale = localStorage.getItem("language");
    return firstValueFrom(this.http.get(`${environment.baseUrl}/api/careers?populate=deep&locale=${locale}`));
  }

  async getCareerContent(){
    const locale = localStorage.getItem("language");
    return firstValueFrom(this.http.get(`${environment.baseUrl}/api/career-home?populate=deep&locale=${locale}`));
  }

  async getCareerById(id:number) {
    const locale = localStorage.getItem("language");
    return firstValueFrom(this.http.get(`${environment.baseUrl}/api/careers/${id}?populate=deep&locale=${locale}`));
  }

  async sendJobApplication(jobApplication: any) {
    return firstValueFrom(this.http.post(`${environment.baseUrl}/api/send-mail`, {
      to: environment.email,
      subject: "Job Application",
      html: `
      <div>
      Name: ${jobApplication.name}<br/>
      Email: ${jobApplication.email}<br/>
      phone: ${jobApplication.phone}<br/>
      Title: ${jobApplication.title}<br/>
      Attachment: <a href="${jobApplication.filePath}">${jobApplication.fileName}</a><br/>
      Link: ${jobApplication.link}<br/>
      Days: ${jobApplication.days}<br/>
      Message: ${jobApplication.message}<br/>
      </div>
      `
    }));
  }

  async getBackgrounds() {
    return firstValueFrom(this.http.get(`${environment.baseUrl}/api/background?populate=*`));
  }

  async getCoworkerInfo(coworker: any) {
    return firstValueFrom(this.http.post(`${environment.baseUrl}/api/send-mail`, {
      to: environment.email,
      subject: "Coworker",
      html: `
      <div>
      Name Surname: ${coworker.fullName}<br/>
      Email: ${coworker.email}<br/>
      Phone: ${coworker.phoneNumber}<br/>
      Coworking Plan: ${coworker.coworkingPlan}<br/>
      Starting Date: ${coworker.startingDate}<br/>
      Profession: ${coworker.profession}<br/>
      Note: ${coworker.note}<br/>
      </div>
      `
    }));
  }

}
