<app-default-page
  icon="window-sidebar"
  [title]="('web-applications.title' | translate)"
  [subTitle]="('web-applications.card-sub-title' | translate)" 
  [subTitle0]="webApplicationTitle"
  [subTitle1]="webApplicationText"
  [categories]="('web-applications.webapp-categories' | translate)"
  [items]="items"
  [backgroundImg]="utilsService.backgrounds.web_applications"
  [processes]="processes"
  [references]="references"
></app-default-page>