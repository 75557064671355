<div *ngIf="graphicDesignServices" class="software-development-container"
  [ngStyle]="{'background-image':'url('+(cmsUrl + graphicDesignServices?.bgImage?.data?.attributes?.url)+')', 'background-position-Y': moveY }">
  <div class="default-padding">
    <h1 class="primary-title">{{graphicDesignServices?.title}}</h1>
    <label class="my-5">{{graphicDesignServices?.description}}</label>

    <div class="mt-5 row mx-auto d-flex flex-row flex-wrap justify-content-center w-100">

      <div class="col-sm-12 col-md-4 software-card" *ngFor="let service of graphicDesignServices?.cards" (click)="goTo(service.redirectTo)"
        (click)="goTo(service.redirectTo)" (mouseenter)="onMouseEnter(service)" (mouseleave)="onMouseLeave(service)">
        <div class="image-container">
          <img [src]="service.hovering ? getHoverIconUrl(service) : getIconUrl(service)" alt="Service Icon"
            class="primary-text bi" [width]="63" [height]="63">
        </div>
        <div class="text-container">
          <h3>{{service.title}}</h3>
          <p>{{service.text}}</p>
          <div class="arrow-right-container">
            <i class="bi bi-arrow-right"></i>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>