import { Component } from '@angular/core';
import { RequestsService } from 'src/app/core/services/requests.service';
import { UtilsService } from 'src/app/core/services/utils.service';
import { environment } from 'src/app/environments';

interface MobileApplications{
  id:number,
  title:string,
  text:string,
  icon:string,
  iconHover:string
}
@Component({
  selector: 'app-mobile-apps',
  templateUrl: './mobile-apps.component.html',
  styleUrls: ['./mobile-apps.component.scss']
})
export class MobileAppsComponent {
  items:MobileApplications[] = [];
  processes:string[]=[]
  references:string[]=[]
  prices:any=[];
  mobileAppTitle= '';
  mobileAppText='';
  cmsUrl = environment.baseUrl;
  
  constructor(private requestsService: RequestsService, public utilsService: UtilsService){}
  ngOnInit(){
  this.getMobileApplications();
  this.getProcesses();
  this.getReferences();
  this.getPrices();
  this.utilsService.setTitle("Mobile Apps - iSolve")
  this.getSeo()
  this.getMobileAppText();
  }
  
  getMobileApplications(){
    this.requestsService.getMobileApplications()
    .then((apps:any)=>{
      this.items = apps.data.map((app:any)=>{return{
        id:app.id,
        title:app.attributes.title,
        text:app.attributes.text,
        iconUrl: `${this.cmsUrl}${app.attributes.icon.data[0].attributes.url}`,
        iconHoverUrl: `${this.cmsUrl}${app.attributes.iconHover.data[0].attributes.url}`
      }})
      .sort((a:any,b:any)=>a.id - b.id);
    })
  }

  getIconUrl(items: any): string {
    return `${this.cmsUrl}${items.attributes.icon.data.attributes.url}`;
  }
  
  getHoverIconUrl(items: any): string {
    return `${this.cmsUrl}${items.iconHover.data.attributes.url}`;
  }
  
  getProcesses(){
    this.requestsService.getMobileProcesses()
    .then((apps:any)=>{
      this.processes = apps.data
      .sort((a:any,b:any)=>a.id - b.id)
      .map((app:any)=>app.attributes)

    })
  }

  getMobileAppText() {
    this.requestsService.getMobileAppText()
      .subscribe((response: any) => {
        this.mobileAppTitle = response.data.attributes.title;
        this.mobileAppText = response.data.attributes.text;
      });
  }
  
  getReferences(){
    this.requestsService.getSelectedProjects()
    .then((apps:any)=>{
      this.references = apps.data
      .filter((app:any)=>app.attributes.category.data.id===3)
      .sort((a:any,b:any)=>a.id - b.id)
      .map((app:any) => {
        return {
          image: `${environment.baseUrl}${app.attributes.image.data[0].attributes.url}`,
          alt: `${environment.baseUrl}${app.attributes.image.data[0].attributes.alternativeText}`
        }
      })
    })
  }

  
  

  getPrices() {
    this.requestsService.getMobilePrices()
      .then((prices: any) => {
        this.prices = prices.data.map((price: any) => {
          return {
            id: price.id,
            title: price.attributes.title,
            subTitle: price.attributes.sub_title,
            price: price.attributes.price,
            isPopular: price.attributes.isPopular,
            options: price.attributes.options.split(";"),
          }
        }).sort((a: any, b: any) => a.id - b.id)
      })
  }
  
  getSeo() {
    this.requestsService.getSeo().then((seo: any) => { 
      this.utilsService.updateTags(seo.data.find((page: any) => page?.attributes?.page == 'MobileApps'))
    })
  }
}
