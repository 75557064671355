import { Component, Input } from '@angular/core';
import { CONOME_MK } from 'src/app/core/constants';
import { environment } from 'src/app/environments';

@Component({
  selector: 'app-conome-section',
  templateUrl: './conome-section.component.html',
  styleUrls: ['./conome-section.component.scss']
})
export class ConomeSectionComponent {
  @Input() conomeSection: any;
  cmsUrl = environment?.baseUrl;

  imgConomeComputer = `${environment.baseUrl}/uploads/conome_fin_9fa4b1357d.png`;
  imgconomeIcon= `${environment.baseUrl}/uploads/Conome_logo_final_icon_67646708ad.svg`;

  goToConome(redirectLink: string){
    window.open(redirectLink,"_blank")
  }
}
