<div *ngIf="ourClient" class="my-5 default-padding">
  <h4 class="primary-title text-center">{{ourClient?.title}}</h4>

  <div class="row mx-auto clients">
    <div class="col-sm-12 col-md-3" *ngFor="let ourClient of ourClient?.clientsLogo?.data">
      <div class="m-3">
        <img [src]="cmsUrl + ourClient?.attributes?.url" [width]="500" [height]="300" [alt]="ourClient.alt" class="w-100"/>
      </div>
    </div>
  </div>
</div>