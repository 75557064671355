<app-default-page
icon="journal"
[title]="('brochures-flyers-catalogs.title'|translate)"
[subTitle]="('brochures-flyers-catalogs.card-sub-title'|translate)" 
[subTitle0]="('brochures-flyers-catalogs.subTitle0'|translate)"
[subTitle1]="('brochures-flyers-catalogs.subTitle1'|translate)"
[categories]="('brochures-flyers-catalogs.webapp-categories'|translate)"
[items]="items"
[backgroundImg]="utilsService.backgrounds.brochures_flyers_catalogs"
[processes]="processes"
[references]="references"
[prices]="prices"

></app-default-page>