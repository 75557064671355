<app-default-page
icon="window-sidebar"
[title]="('desktop-applications.title'|translate)"
[subTitle]="('desktop-applications.card-sub-title'|translate)" 
[subTitle0]="desktopAppTitle"
[subTitle1]="desktopAppText"
[categories]="('desktop-applications.webapp-categories'|translate)"
[items]="items"
[backgroundImg]="utilsService.backgrounds.desktop_applications"
[processes]="processes"
[references]="references"

></app-default-page>