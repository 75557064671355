import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MenuComponent } from './core/components/menu/menu.component';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { RequestsInterceptor } from './requests.interceptor';
import { FooterComponent } from './core/components/footer/footer.component';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HomeComponent } from './core/components/home/home.component';
import { SoftwareDevelopmentComponent } from './core/components/home/software-development/software-development.component';
import { WebsiteServicesComponent } from './core/components/home/website-services/website-services.component';
import { GraphicDesignServicesComponent } from './core/components/home/graphic-design-services/graphic-design-services.component';
import { WorkFromHomeBoringComponent } from './core/components/home/work-from-home-boring/work-from-home-boring.component';
import { SelectedProjectsComponent } from './core/components/home/selected-projects/selected-projects.component';
import { ProductsStatsComponent } from './core/components/home/products-stats/products-stats.component';
import { TechnologiesComponent } from './core/components/home/technologies/technologies.component';
import { ConomeSectionComponent } from './core/components/home/conome-section/conome-section.component';
import { OurClientsComponent } from './core/components/home/our-clients/our-clients.component';
import { PriceOverviewComponent } from './core/components/home/price-overview/price-overview.component';
import { PagesComponent } from './core/components/home/pages/pages.component';
import { FaqComponent } from './core/components/home/faq/faq.component';
import { SocialMediaComponent } from './core/components/home/social-media/social-media.component';
import { LetsTalkComponent } from './core/components/home/lets-talk/lets-talk.component';
import { ProjectInMindComponent } from './core/components/home/project-in-mind/project-in-mind.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { WebApplicationsComponent } from './core/pages/software-development/web-applications/web-applications.component';
import { MobileAppsComponent } from './core/pages/software-development/mobile-apps/mobile-apps.component';
import { DesktopApplicationsComponent } from './core/pages/software-development/desktop-applications/desktop-applications.component';
import { BrochuresFlyersCatalogsComponent } from './core/pages/graphic-design-services/brochures-flyers-catalogs/brochures-flyers-catalogs.component';
import { MoreGraphicDesignServicesComponent } from './core/pages/graphic-design-services/more-graphic-design-services/more-graphic-design-services.component';
import { WebDesignComponent } from './core/pages/professional-website-services/web-design/web-design.component';
import { MaintenanceComponent } from './core/pages/professional-website-services/maintenance/maintenance.component';
import { OnlineMarketingComponent } from './core/pages/professional-website-services/online-marketing/online-marketing.component';
import { LogoDesignComponent } from './core/pages/graphic-design-services/logo-design/logo-design.component';
import { BusinessCardsComponent } from './core/pages/graphic-design-services/business-cards/business-cards.component';
import { LetterheadComponent } from './core/pages/graphic-design-services/letterhead/letterhead.component';
import { InteractivePdfFormsComponent } from './core/pages/graphic-design-services/interactive-pdf-forms/interactive-pdf-forms.component';
import { PhaseComponent } from './core/components/phase/phase.component';
import { MobilePriceOverviewComponent } from './core/components/mobile-app-price-overview/price-overview.component';
import { PricesComponent } from './core/components/prices/prices.component';
import { DefaultPageComponent } from './core/components/default-page/default-page.component';
import { AboutUsComponent } from './core/pages/more/about-us/about-us.component';
import { CareersComponent } from './core/pages/more/careers/careers.component';
import { CareerPositionComponent } from './core/components/career-position/career-position.component';
import { ContactUsComponent } from './core/pages/more/contact-us/contact-us.component';
import { CoworkingComponent } from './core/pages/coworking/coworking.component';
import { FullTextDialogComponent } from './core/components/full-text-dialog/full-text-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { FirstSectionComponent } from './core/components/home/first-section/first-section.component';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { ScrollingModule } from '@angular/cdk/scrolling';


// AoT requires an export function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    MenuComponent,
    FooterComponent,
    HomeComponent,
    SoftwareDevelopmentComponent,
    WebsiteServicesComponent,
    GraphicDesignServicesComponent,
    WorkFromHomeBoringComponent,
    SelectedProjectsComponent,
    ProductsStatsComponent,
    TechnologiesComponent,
    ConomeSectionComponent,
    OurClientsComponent,
    PriceOverviewComponent,
    PagesComponent,
    FaqComponent,
    SocialMediaComponent,
    LetsTalkComponent,
    ProjectInMindComponent,
    WebApplicationsComponent,
    MobileAppsComponent,
    DesktopApplicationsComponent,
    BrochuresFlyersCatalogsComponent,
    MoreGraphicDesignServicesComponent,
    WebDesignComponent,
    MaintenanceComponent,
    OnlineMarketingComponent,
    LogoDesignComponent,
    BusinessCardsComponent,
    LetterheadComponent,
    InteractivePdfFormsComponent,
    PhaseComponent,
    MobilePriceOverviewComponent,
    PricesComponent,
    DefaultPageComponent,
    AboutUsComponent,
    CareersComponent,
    CareerPositionComponent,
    ContactUsComponent,
    CoworkingComponent,
    FullTextDialogComponent,
    FirstSectionComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    MatDialogModule,
    CommonModule,
    NgOptimizedImage,
    ScrollingModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestsInterceptor,
      multi: true,
    },],
  bootstrap: [AppComponent]
})
export class AppModule { }
