<app-default-page 
icon="pencil"
[title]="('logo-design.title'|translate)"
[subTitle]="('logo-design.card-sub-title'|translate)" 
[subTitle0]="('logo-design.subTitle0'|translate)"
[subTitle1]="('logo-design.subTitle1'|translate)"
[categories]="('logo-design.webapp-categories'|translate)"
[items]="items"
[backgroundImg]="utilsService.backgrounds.logo_design"
[processes]="processes"
[references]="references"
[prices]="prices"

></app-default-page>