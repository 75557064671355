<div *ngIf="faqs" class="faqs-container py-5 default-padding">
<h2 class="primary-title text-center my-5 py-5">{{faqs?.title}}</h2>

<div>
  <div class="row mx-auto">
    <div class="col-sm-12 col-md-6" *ngFor="let faq of faqs?.questionAnswer;let i=index" 
      [ngClass]="i % 2 === 0 ? 'text-start' : 'text-end' ">
      <label class="faq-question">{{faq.question}}</label>
      <label class="faq-answer my-5">{{faq.answer}}</label>
    </div>
  </div>
</div>

</div>