<app-default-page
icon="people"
[title]="('business-cards.title'|translate)"
[subTitle]="('business-cards.card-sub-title'|translate)" 
[subTitle0]="('business-cards.subTitle0'|translate)"
[subTitle1]="('business-cards.subTitle1'|translate)"
[categories]="('business-cards.webapp-categories'|translate)"
[items]="items"
[backgroundImg]="utilsService.backgrounds.business_cards"
[processes]="processes"
[references]="references"
[prices]="prices"
[isPriceFrom]="false"

></app-default-page>